//================================================================
//  Component: Review and Negotiate Tab
//================================================================

//  Purpose: This contains the review and negotiate information for a commercial task

//  Properties:
//    - task = {An object, contains the commercial task details}

//  Example:
//    <ReviewAndNegotiateTab
//      task={task}
//    ></ReviewAndNegotiateTab>    

// ==============================================================

// Libraries
import React, { useReducer, useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

// Contexts
import { GetUser } from '../../../../Library/GlobalContexts';
import { SetToast } from '../../../../Library/GlobalContexts';

// Components
import AddApproverPane from './AddApproverPane';
import ViewApproverPane from './ViewApproverPane';
import CancelApprovalModal from './CancelApprovalModal';
import Tooltip from '../../../../Components/Tooltip/Tooltip';
import CommentsThread from './CommentsThread';
import LiveField from '../../../../Components/LiveField/LiveField';
import AddPOLineItemPane from './AddPOLineItemPane';
import KebabDropdown from '../../../../Components/KebabDropdown/KebabDropdown';
import EditPOLineItemPane from './EditPOLineItemPane';

// Functions
import ConvertDate from '../../../../Library/ConvertDate';
import WriteDocument from '../../../../Library/WriteDocument';
import QueryListener from '../../../../Library/QueryListener';

// Images
import FileIcon from '../../../../Components/Images/Icon_File_Black.svg';
import Expand from '../../../../Components/Images/Icon_Collapse_Teal.svg';
import Collapse from '../../../../Components/Images/Icon_Expand_Teal.svg';
import Add from '../../../../Components/Images/Icon_Add_Teal.svg';
import AddDisabled from '../../../../Components/Images/Icon_Add_Grey.svg';
import CancelDisabled from '../../../../Components/Images/Icon_CancelDisabled_Grey.svg';
import InfoRequired from '../../../../Components/Images/Icon_InfoRequired_Blue.svg';
import Info from '../../../../Components/Images/Icon_Info_Blue.svg';

// CSS
import '../../Task.css'

export default function ReviewAndNegotiateTab({
    task
}) {

    //------------------------------------------------------
    //  useContexts and React Router
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setToast = useContext(SetToast);
    const navigate = useNavigate();

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to toggle the visibility of the add approver pane
    const [addApproverPaneOpen, setAddApproverPaneOpen] = useState(false);

    // Used to toggle the visibility of the view approver pane and save the current approval
    const [viewPaneApprover, setViewPaneApprover] = useState(undefined);

    // Used to toggle the visibility of the cancel approval modal and save the current approval
    const [cancelModalApprover, setCancelModalApprover] = useState(undefined);

    // Used to toggle the visibility of the add approver pane
    const [addPOLineItemPaneOpen, setAddPOLineItemPaneOpen] = useState(false);

    // Used to toggle the visibility of the add approver pane
    const [editPOLineItemPaneOpen, setEditPOLineItemPaneOpen] = useState(false);

    // Used to store the selected PO Line Item
    const [selectedPOLineItemIndex, setSelectedPOLineItemIndex] = useState(null);

    // Used to toggle of the raise PO checkbox
    const [isRaisePOChecked, setIsRaisePOChecked] = useState(task?.poraised ?? false);

    // Used to toggle of the raise PO checkbox
    const [isExecutionRequired, setIsExecutionRequired] = useState(task?.executionnotrequired ?? false);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store whether a section is expanded
    const [taskSection, setTaskSection] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'digitalCommercialRequestCollapsed': true,
            'firstRoundApprovalsCollapsed': true,
            'secondRoundApprovalsCollapsed': true,
            'commentsCollapsed': true,
            'poRequestCollapesed': true,
            'contractInformationCollapsed': true,
            'financialInformationCollapsed': true,
        }
    );

    // Used to store the first and second round approvals
    const [approvalData, setApprovalData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'firstRoundApprovals': [],
            'secondRoundApprovals': []
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    //================================================================
    //  Function: assignField
    //================================================================
    //  Purpose: Assigns a field to the procurementTask object based 
    //           on whether the field exists in potask or task.
    //           If the field is undefined in potask, it assigns 
    //           the value from task instead.
    //
    //  Parameters:
    //    - procurementTask: The main object that collects all data for procurement.
    //    - potask: Contains task-specific data, which may override task fields.
    //    - task: Contains the default data for each field if potask does not provide it.
    //    - field: The specific field name to be assigned.
    //================================================================

    function assignField(procurementTask, potask, task, field) {
        procurementTask[field] = potask[field] !== undefined ? potask[field] : task[field];
    }

    // Helper function to assign fields from task to updatedDoc.potask
    function assignFieldIfUndefined(updatedDoc, potask, task, field) {
        // Ensure updatedDoc.potask exists before assigning any fields
        updatedDoc.potask = updatedDoc?.potask || {};

        // Check if the field in potask is undefined and assign from task
        if (potask[field] === undefined) {
            updatedDoc.potask[field] = task[field];
        }
    }

    // Function used to proceed to 'Execute' stage
    function moveToNextStage() {

        const allPromises = [];

        // Array of fields to assign to the procurementTask
        // This array contains the specific fields we want to populate in procurementTask.
        // By iterating over this array, we can dynamically assign values from potask and task,
        // reducing the need for repetitive code and making it easier to manage the fields being assigned.

        const fieldsToAssign = ['vendor', 'sponsorname', 'agreementtype', 'contractstartdate', 'estannualcontractvalue', 'esttotalcontractvalue', 'currency', 'lendleasecompany'];

        //=================================================
        // Check PO Task Raise or not
        //=================================================

        if (task?.poraised === true) {

            // Generate IDs
            const newTaskId = `task-${Date.now().toString()}${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`;

            const procurementTask = {
                'taskid': newTaskId,
                'requestid': task?.requestid,
                'tasktype': 'Procurement',
                'taskname': 'Commercial Procurement Request',
                'taskdescription': `New PO Request for ${task?.applicationname}`,
                'applicationname': task?.applicationname,
                'stage': '',
                'status': 'Not Started',
                'assignmentgroup': 'Procurement',
                'requesttype': 'Procurement',
                'activityfeedvisible': true,
                'approvalhubvisible': false,
                'region': task?.region,
                'assignedto': {
                    'email': 'Unassigned',
                    'givenname': '',
                    'surname': ''
                },
                'assigneddate': '',
                'createddate': new Date(),
                'createdby': {
                    'email': getUser?.emailaddress,
                    'givenname': getUser?.givenname,
                    'surname': getUser?.surname
                },
                'lastmodifieddate': new Date(),
                'lastmodifiedby': {
                    'email': getUser?.emailaddress,
                    'givenname': getUser?.givenname,
                    'surname': getUser?.surname
                },
                'activityfeed': [
                    {
                        'activitydate': new Date(),
                        'actionedby': {
                            'email': getUser?.emailaddress,
                            'givenname': getUser?.givenname,
                            'surname': getUser?.surname
                        },
                        'action': 'created',
                        'comments': '',
                    }
                ],

                // Procurement Task Fields
                'requestername': task?.requestername,
                'shortdescription': task?.potask?.shortdescription,
                'commercialscope': task?.potask?.commercialscope,
                'poanumber': task?.poanumber,
                'agreementtype': task?.potask?.agreementtype,
                'lendleasecompany': task?.potask?.lendleasecompany,
                'parentcontract': task?.potask?.parentcontract === undefined ? '' : task?.potask?.parentcontract,
                'contractstartdate': task?.potask?.contractstartdate,
                'contractenddate': task?.potask?.contractenddate,
                'documentstoragelink': task?.potask?.documentstoragelink,
                'currency': task?.potask?.currency === undefined ? '' : task?.potask?.currency,
                'estannualcontractvalue': task?.potask?.estannualcontractvalue === undefined ? '' : task?.potask?.estannualcontractvalue,
                'esttotalcontractvalue': task?.potask?.esttotalcontractvalue === undefined ? '' : task?.potask?.esttotalcontractvalue,
                'ponumber': task?.potask?.ponumber === undefined ? '' : task?.potask?.ponumber,
                'polineitems': task?.potask?.polineitems === undefined ? [] : task?.potask?.polineitems,
                'digitalcommercialrequest': {
                    'requestid': task?.requestid,
                    'poanumber': task?.poanumber,
                    'requesttype': task?.requesttype,
                    'applicationname': task?.applicationname,
                    'shortdescription': task?.shortdescription,
                    'requestername': task?.requestername,
                    'region': task?.region,
                    'sponsorname': task?.sponsorname,
                    'createdby': task?.createdby,
                    'supportingdocs': task?.supportingdocs === undefined ? [] : task?.supportingdocs,
                    'commercialscope': task?.commercialscope,
                    'buysellagreement': task?.buysellagreement,
                    'agreementtype': task?.agreementtype,
                    'contractstartdate': task?.contractstartdate,
                    'lendleasecompany': task?.lendleasecompany,
                    'vendor': {
                        'vendorname': task?.vendor?.vendorname === undefined ? '' : task?.vendor?.vendorname,
                        'vendorid': task?.vendor?.vendorid === undefined ? '' : task?.vendor?.vendorid,
                        'tradingas': task?.vendor?.tradingas === undefined ? '' : task?.vendor?.tradingas
                    },
                    'currency': task?.currency,
                    'estannualcontractvalue': task?.estannualcontractvalue,
                    'esttotalcontractvalue': task?.esttotalcontractvalue,
                    'projectcode': task?.projectcode,
                    'tasknumber': task?.tasknumber,
                    'expendituretype': task?.expendituretype,
                }
            }

            // Assign fields using the assignField function
            fieldsToAssign.forEach(field => assignField(procurementTask, task.potask, task, field));

            allPromises.push(
                WriteDocument('tasks', newTaskId, procurementTask, false)
            )
        }

        //=================================================
        //  Update document in Firestore
        //=================================================

        const updatedDoc = {
            'stage': task?.executionnotrequired === true ? 'Completed' : 'Execute',
            'status': task?.executionnotrequired === true ? 'Completed' : 'In Progress',
            'lastmodifiedby': {
                'email': getUser.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'lastmodifieddate': new Date()
        };

        // Usage of the helper function for each field
        // There are two sources for information:
        // 1. The root object contains default details.
        // 2. 'potask' contains the live data, which is directly saved to the database upon update.
        //
        // The workflow is as follows:
        // - If the property in 'potask' is not available (i.e., undefined), we should use the default details from the root object.
        // - When the user updates the information, it is saved directly to 'potask', reflecting changes live in the database.
        // - If the user does not explicitly update the details in 'potask', we need to copy the default information from the root object 
        //   into 'potask' when the user clicks the submit button, ensuring the correct values are saved to the database.

        if (task?.poraised === true) {

            fieldsToAssign.forEach(field => assignFieldIfUndefined(updatedDoc, task.potask, task, field));

        }

        // Update "activityfeed" only if execution is not required,
        // as the task will be marked as "Completed" in this case.
        if (task?.executionnotrequired === true) {
            updatedDoc.activityfeed = [
                ...task?.activityfeed,
                {
                    'activitydate': new Date(),
                    'actionedby': {
                        'email': getUser?.emailaddress,
                        'givenname': getUser?.givenname,
                        'surname': getUser?.surname
                    },
                    'action': 'completed',
                    'comments': ''
                }
            ];
        }

        allPromises.push(
            WriteDocument('tasks', task?.taskid, updatedDoc, true)
        )

        return Promise.all(allPromises)
        .then(() => {

            // Go to the Execute Tab
            navigate(`/${window.location.pathname.split('/')[1]}/${task?.taskid}?view=execute`)

            // Scroll to the top of the page
            const body = document.querySelector('.PageComponent-Container');

            body.scrollIntoView({
                behavior: 'smooth'
            }, 300);
            
        })
        .catch((error) => {
            console.log('Error', error)

            // NH TODO - WE NEED TO ADD AN ERROR STATE HERE

        })

    }

    // Function used to handle the raise PO checkbox
    function handleRaisePO(value) {

        setIsRaisePOChecked(value);

        // If value is True need to open PO Request Section, Contract Information and Financial Information
        if (value) {

            setTaskSection({
                'poRequestCollapesed': false,
                'contractInformationCollapsed': false,
                'financialInformationCollapsed': false
            });

        } else {
            
            setTaskSection({
                'poRequestCollapesed': true,
                'contractInformationCollapsed': true,
                'financialInformationCollapsed': true
            });

        }

        // Update the document
        const updatedDoc = {
            'poraised': value,
        };

        WriteDocument('tasks', task?.taskid, updatedDoc, true)
        .catch((error) => {

            setToast({
                'type': 'error',
                'message': 'Failed to update PO Raise status',
            });

            // Set the value back to the original value
            setIsRaisePOChecked(!value);
            console.log('Error', error)

        });

    }

    // Function used to handle the Execution Required checkbox
    function handleExecutionNotRequired(value) {

        setIsExecutionRequired(value);

        // Update the document
        const updatedDoc = {
            'executionnotrequired': value,
        };

        WriteDocument('tasks', task?.taskid, updatedDoc, true)
            .catch((error) => {

                setToast({
                    'type': 'error',
                    'message': 'Failed to update PO Raise status',
                });

                // Set the value back to the original value
                setIsExecutionRequired(!value);
                console.log('Error', error)

            });

    }

    // Handle edit button click and set the selected PO line item
    const handlePOItemEditClick = (polineitemIndex) => {

        // Set the selected PO Line Item Index
        setSelectedPOLineItemIndex(polineitemIndex);

        // Open the Edit PO Line Item Pane
        setEditPOLineItemPaneOpen(true);

    };

    // Handle delete button click and set the selected PO line item
    const handlePOItemDeleteClick = (polineitemIndex) => {

        const updatedPOLineItems = task?.potask?.polineitems.filter((item, index) => index !== polineitemIndex);

        // Update the document
        const updatedDoc = {
            'potask': {
                'polineitems': updatedPOLineItems
            }
        };

        WriteDocument('tasks', task?.taskid, updatedDoc, true)
            .catch((error) => {

                setToast({
                    'type': 'error',
                    'message': 'Failed to delete PO Line Item',
                });

                console.log('Error', error)

            })
    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Query Listener
    // - Get first and second round approvals 
    useEffect(() => {

        if (task === undefined) return;
        if (task?.requestid === undefined) return;

        function onLoadChange(document) {

            const firstRoundApprovalTypes = [
                'Line Manager Approval',
                'Finance Manager Approval',
                'Corporate Approval'
            ]

            setApprovalData({
                'firstRoundApprovals': document.filter((task) => firstRoundApprovalTypes.includes(task?.tasktype)),
                'secondRoundApprovals': document.filter((task) => task?.tasktype === 'Commercial Second Round Approval')
            })

        }

        function onError(error) {

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to get first and second round approvals',
            });

        }

        const unsubscribe = QueryListener('tasks', [
            ['requestid', '==', task?.requestid],
        ], onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, [task])

    //------------------------------------------------------
    //  Constants
    //------------------------------------------------------

    // Validate if the vendor ID is missing or hasn't been updated
    const isVendorIdValid = task?.potask?.vendor?.vendorid?.length > 0 ||
        (task?.potask?.vendor?.vendorid === undefined && task?.vendor?.vendorid?.length > 0);

    // Validate if the sponsor email is missing or hasn't been updated
    const isSponsorEmailValid = task?.potask?.sponsorname?.email?.length > 0 ||
        (task?.potask?.sponsorname?.email === undefined && task?.sponsorname?.email?.length > 0);


    // Validate if the agreement type is missing or hasn't been updated
    const isAgreementTypeValid = task?.potask?.agreementtype?.length > 0 ||
        (task?.potask?.agreementtype === undefined && task?.agreementtype?.length > 0);

    // Validate if the contract start date is missing or hasn't been updated
    const isContractStartDateValid = task?.potask?.contractstartdate !== undefined ||
        (task?.potask?.contractstartdate === undefined && task?.contractstartdate !== undefined);

    // Validate if the Lendlease Company is missing or hasn't been updated
    const isLendleaseCompanyValid = task?.potask?.lendleasecompany?.length > 0 ||
        (task?.potask?.lendleasecompany === undefined && task?.lendleasecompany?.length > 0);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <div className='flex flex-col gap-2 p-0'>

            {/* ===================================================== */}
            {/*  Digital Commercial Request                           */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'digitalCommercialRequestCollapsed': !taskSection.digitalCommercialRequestCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.digitalCommercialRequestCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Digital Commercial Request</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.digitalCommercialRequestCollapsed}></hr>

                {/* ------------- COMMERCIAL REQUEST FORM ------------- */}

                <div className='p-[3%]' hidden={taskSection?.digitalCommercialRequestCollapsed}>

                    {/* ------------- REQUEST INFORMATION ------------- */}

                    <h5 className='text-[18px] text-teal-600 mb-2'>Request Information</h5>

                    {/* =========================================================== */}
                    {/*  1. Request ID                                              */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 1. Request ID </label>
                        <input
                            className='Input-Field-Text uppercase'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.requestid}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  2. PoA Number                                              */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 2. PoA Number </label>
                        <input
                            className='Input-Field-Text uppercase'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.poanumber}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  3. Request Type                                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 3. Request Type </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            value={task?.requesttype}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  4. Application Name                                        */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 4. Application Name </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            value={task?.applicationname}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  5. Short Description                                       */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 5. Short Description </label>
                        <textarea
                            className='Input-Field-TextArea'
                            type='text'
                            value={task?.shortdescription}
                            disabled
                        ></textarea>
                    </div>

                    {/* =========================================================== */}
                    {/*  6. Requester Name                                          */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 6. Requester Name </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            value={task?.requestername}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  7. Region                                                  */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 7. Region </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.region}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  8. Sponsor Name                                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 8. Sponsor Name </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.sponsorname.email}</option>
                        </select>
                    </div>

                    {/* =========================================================== */}
                    {/*  9. Created By                                              */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 9. Created By </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.createdby?.email}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  10. Supporting Documents                                   */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 10. Supporting Documents </label>
                        {
                            task?.supportingdocs.length === 0 ?
                                (
                                    <div>
                                        No documents found.
                                    </div>
                                )
                                :
                                (
                                    <table className='table-auto w-full border-separate border-spacing-0 border-1 border-solid border-[#D8D8D8] rounded-[10px]'>
                                        <thead>
                                            <tr>
                                                <th className='font-normal px-3 py-2'>Uploaded Documents</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                task?.supportingdocs.map((file, index) => (
                                                    <tr key={index}>
                                                        <td className='flex flex-row gap-2 px-3 py-2 text-[#0972D3] underline border-t border-t-[#D8D8D8]'>
                                                            <img src={FileIcon} alt='file'></img>
                                                            <a href={file?.fileUrl} target='_blank' rel='noreferrer'>
                                                                {file?.fileName}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                )
                        }
                    </div>

                    {/* ------------- CONTRACT INFORMATION ------------- */}

                    <h5 className='text-[18px] text-teal-600 mt-4 mb-2'>Contract Information</h5>

                    {/* =========================================================== */}
                    {/*  11. Commercial Scope                                       */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium mb-0'> 11. Commercial Scope Description </label>
                        <textarea
                            className='Input-Field-TextArea'
                            value={task?.commercialscope}
                            disabled
                        ></textarea>
                    </div>

                    {/* =========================================================== */}
                    {/*  12. Buy / Sell Agreement                                   */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 12. Is this a buy or sell agreement? </label>
                        <select className='Input-Field-Select' style={{ width: '200px' }} disabled>
                            <option>{task?.buysellagreement}</option>
                        </select>
                    </div>

                    {/* =========================================================== */}
                    {/*  13. Agreement Type                                         */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 13. Agreement Type </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.agreementtype}</option>
                        </select>
                    </div>

                    {/* =========================================================== */}
                    {/*  14. Contract Start Date                                    */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 14. Contract Start Date </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            value={task?.contractstartdate}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  15. Lendlease Company                                      */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 15. Lendlease Company (Signing Entity) </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.lendleasecompany}</option>
                        </select>
                    </div>

                    {/* ------------- OTHER PARTY INFORMATION (VENDOR/CUSTOMER) ------------- */}

                    <h5 className='text-[18px] text-teal-600 mt-4 mb-2'> Other Party Information (Vendor / Customer) </h5>

                    {/* =========================================================== */}
                    {/*  16. Vendor Name                                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 16. Vendor Name </label>
                        {
                            task?.newvendorname?.length === 0 ?

                                // Existing Vendor
                                <input
                                    className='Input-Field-Text'
                                    type='text'
                                    value={task?.vendor?.vendorname}
                                    disabled
                                ></input>

                                :

                                // New Vendor
                                <div className='flex flex-col justify-center bg-[#F5F5F5] px-4 py-3 rounded-md border border-[#D8D8D8]'>

                                    {/* Header */}
                                    <div className='flex flex-row gap-2 items-center'>
                                        <img src={InfoRequired} alt='info'></img>
                                        <label className='font-medium text-[#5E93AC]'>New Vendor Request</label>
                                    </div>

                                    {/* Form */}
                                    <div className='grid grid-cols-2 gap-2'>

                                        {/* Column 1 */}
                                        <div className='flex flex-col gap-2 px-2 mt-0 mb-2'>

                                            {/* Vendor Name */}
                                            <div className='Task-Form-Row'>
                                                <label className='font-medium'> Vendor Name </label>
                                                <input
                                                    className='Input-Field-Text'
                                                    style={{ width: '100%' }}
                                                    value={task?.newvendorname}
                                                    type='text'
                                                    disabled
                                                ></input>
                                            </div>

                                            {/* Vendor Phone */}
                                            <div className='Task-Form-Row'>
                                                <label className='font-medium'> Vendor Phone </label>
                                                <input
                                                    className='Input-Field-Text'
                                                    style={{ width: '100%' }}
                                                    value={task?.newvendorcontactphone}
                                                    type='text'
                                                    disabled
                                                ></input>
                                            </div>

                                        </div>

                                        {/* Column 2 */}
                                        <div className='flex flex-col gap-2 px-2 mt-0 mb-2'>

                                            {/* Vendor Trading As Name */}
                                            <div className='Task-Form-Row'>
                                                <label className='font-medium'> Vendor Trading As Name </label>
                                                <input
                                                    className='Input-Field-Text'
                                                    style={{ width: '100%' }}
                                                    value={task?.newvendortradingname}
                                                    type='text'
                                                    disabled
                                                ></input>
                                            </div>

                                            {/* Vendor Email */}
                                            <div className='Task-Form-Row'>
                                                <label className='font-medium'> Vendor Email </label>
                                                <input
                                                    className='Input-Field-Text'
                                                    style={{ width: '100%' }}
                                                    value={task?.newvendorcontactemail}
                                                    type='text'
                                                    disabled
                                                ></input>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                        }
                    </div>

                    {/* ------------- FINANCIAL INFORMATION ------------- */}

                    <h5 className='text-[18px] text-teal-600 mt-4 mb-2'> Financial Information </h5>

                    {/* =========================================================== */}
                    {/*  17. Currency                                               */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 17. Currency </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.currency}</option>
                        </select>
                    </div>

                    {/* =========================================================== */}
                    {/*  18. Estimated Annual Contract Value (eTax)                 */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 18. Estimated Annual Contract Value (eTax) </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.estannualcontractvalue}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  19. Estimated Total Contract Value (eTax)                  */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 19. Estimated Total Contract Value (eTax) </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '300px' }}
                            type='text'
                            value={task?.esttotalcontractvalue}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  20. Project Code                                           */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 20. Project Code </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.projectcode}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  21. Task Number                                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 21. Task Number </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.tasknumber}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  22. Expenditure Type                                       */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 22. Expenditure Type </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px' }}
                            type='text'
                            value={task?.expendituretype}
                            disabled
                        ></input>
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  First Round Approvals                                */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'firstRoundApprovalsCollapsed': !taskSection.firstRoundApprovalsCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.firstRoundApprovalsCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>First Round Approvals</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.firstRoundApprovalsCollapsed}></hr>

                {/* ------------- FIRST ROUND APPROVALS ------------- */}

                <div className='p-[3%]' hidden={taskSection?.firstRoundApprovalsCollapsed}>

                    {/*  Approvals Table */}
                    <div className='border border-[#D8D8D8] rounded px-[1%]'>
                        <table className='w-full max-h-96 overflow-y-scroll'>
                            <thead>
                                <tr className='text-[#424242]'>
                                    <th className='py-[15px] px-[5px] font-medium'></th>
                                    <th className='py-[15px] px-[10px] font-medium'>Approver</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Type</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Modified</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    approvalData?.firstRoundApprovals?.length === 0 || approvalData?.firstRoundApprovals === undefined ?
                                        (
                                            <tr>
                                                <td className='border-t border-t-[#D8D8D8]' colSpan={6}>
                                                    <div className='text-center p-[2%]'>
                                                        No approvals found.
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            approvalData?.firstRoundApprovals?.map((item, index) => (
                                                <tr key={index} className='hover:bg-[#F0F7F7]'>

                                                    <td className='py-[10px] px-[5px] border-t border-t-[#D8D8D8]'></td>

                                                    {/* Assignee*/}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]' onClick={() => { setViewPaneApprover(item) }}>
                                                        <p className='m-0 cursor-pointer hover:underline hover:font-medium'>{item?.assignedto?.email}</p>
                                                    </td>

                                                    {/* Type */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        <div className='max-w-[250px] leading-normal whitespace-nowrap overflow-hidden text-ellipsis'>
                                                            {item?.tasktype}
                                                        </div>
                                                    </td>

                                                    {/* Last Modified Date */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>{ConvertDate(item?.lastmodifieddate)}</td>

                                                    {/* Status */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        {
                                                            [
                                                                { 'name': 'In Progress', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'In Progress' },
                                                                { 'name': 'Approved', 'background': '#E7F2DA', 'color': '#237B4B', 'bordercolor': '#237B4B', 'status': 'Completed' },
                                                                { 'name': 'Rejected', 'background': '#FCF4F6', 'color': '#C4314B', 'bordercolor': '#C4314B', 'status': 'Rejected' },
                                                                { 'name': 'Cancelled', 'background': '#EBEBEB', 'color': '#616161', 'bordercolor': '#616161', 'status': 'Cancelled' },

                                                            ].filter((style) => style?.status === item?.status).map((object, index) => (
                                                                <label key={index} className='Status-Label' style={{ backgroundColor: `${object.background}`, color: `${object.color}`, border: `1px solid ${object.bordercolor}` }} >
                                                                    {object.name}
                                                                </label>
                                                            ))
                                                        }
                                                    </td>

                                                </tr>
                                            ))
                                        )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Second Round Approvals                               */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'secondRoundApprovalsCollapsed': !taskSection.secondRoundApprovalsCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.secondRoundApprovalsCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Second Round Approvals</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.secondRoundApprovalsCollapsed}></hr>

                {/* ------------- SECOND ROUND APPROVALS ------------- */}

                <div className='p-[3%]' hidden={taskSection?.secondRoundApprovalsCollapsed}>

                    {/*  Approvals Table */}
                    <div className='border border-[#D8D8D8] rounded px-[1%]'>
                        <table className='w-full max-h-96 overflow-y-scroll'>
                            <thead>
                                <tr className='text-[#424242]'>
                                    <th className='py-[15px] px-[5px] font-medium'></th>
                                    <th className='py-[15px] px-[10px] font-medium'>Approver</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Subject</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Modified</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Status</th>
                                    <th className='py-[15px] px-[10px] font-medium'>
                                        <Tooltip
                                            message={'+ Add Approver'}
                                            children={
                                                <img className='cursor-pointer' src={task?.status === 'Completed' ? AddDisabled : Add} alt='add' onClick={() => setAddApproverPaneOpen(true)}></img>
                                            }
                                        ></Tooltip>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    approvalData?.secondRoundApprovals?.length === 0 || approvalData?.secondRoundApprovals === undefined ?
                                        (
                                            <tr>
                                                <td className='border-t border-t-[#D8D8D8]' colSpan={6}>
                                                    <div className='text-center p-[2%]'>
                                                        No approvals found.
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            approvalData?.secondRoundApprovals?.map((item, index) => (
                                                <tr key={index} className='hover:bg-[#F0F7F7]'>

                                                    <td className='py-[10px] px-[5px] border-t border-t-[#D8D8D8]'></td>

                                                    {/* Approver Email */}
                                                    <td className='flex flex-col py-[10px] px-[10px] border-t border-t-[#D8D8D8]' onClick={() => {
                                                        setViewPaneApprover(item)
                                                    }}>
                                                        <p className='m-0 cursor-pointer hover:underline hover:font-medium'>{item?.assignedto?.email}</p>
                                                        <p className='text-[13px] m-0 text-slate-600'>{item?.approverrole}</p>
                                                    </td>

                                                    {/* Subject */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        <div className='max-w-[250px] leading-normal whitespace-nowrap overflow-hidden text-ellipsis'>
                                                            {item?.emailsubject}
                                                        </div>
                                                    </td>

                                                    {/* Last Modified Date */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>{ConvertDate(item?.lastmodifieddate)}</td>

                                                    {/* Status */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        {
                                                            [
                                                                { 'name': 'In Progress', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'In Progress' },
                                                                { 'name': 'Approved', 'background': '#E7F2DA', 'color': '#237B4B', 'bordercolor': '#237B4B', 'status': 'Completed' },
                                                                { 'name': 'Rejected', 'background': '#FCF4F6', 'color': '#C4314B', 'bordercolor': '#C4314B', 'status': 'Rejected' },
                                                                { 'name': 'Cancelled', 'background': '#EBEBEB', 'color': '#616161', 'bordercolor': '#616161', 'status': 'Cancelled' },

                                                            ].filter((style) => style?.status === item?.status).map((object, index) => (
                                                                <label key={index} className='Status-Label' style={{ backgroundColor: `${object.background}`, color: `${object.color}`, border: `1px solid ${object.bordercolor}` }} >
                                                                    {object.name}
                                                                </label>
                                                            ))
                                                        }
                                                    </td>

                                                    {/* Cancel */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        {
                                                            (item?.status !== 'In Progress') ?
                                                                <img className='cursor-not-allowed' src={CancelDisabled} alt='cancel'></img>
                                                                :
                                                                <Tooltip
                                                                    message={'Cancel Approval'}
                                                                    children={
                                                                        <div className='Cancel-Icon min-w-[30px]' onClick={() => setCancelModalApprover(item)}></div>
                                                                    }
                                                                ></Tooltip>
                                                        }
                                                    </td>

                                                </tr>
                                            ))
                                        )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Comments                                             */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'commentsCollapsed': !taskSection.commentsCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.commentsCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Comments</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.commentsCollapsed}></hr>

                {/* ------------- COMMENTS ------------- */}

                <div className='p-[3%]' hidden={taskSection?.commentsCollapsed}>

                    <CommentsThread
                        task={task}
                    ></CommentsThread>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Raise PO Checkbox                                    */}
            {/* ===================================================== */}

            <div className='flex flex-row items-start gap-2 mb-2 mt-4'>
                <label className='Checkbox-Container'>
                    <input
                        required
                        type='checkbox'
                        name='confirmation'
                        checked={isRaisePOChecked}
                        onChange={() => handleRaisePO(!isRaisePOChecked)}
                        disabled={task?.status === 'Completed' || task?.stage !== 'Review and Negotiate'}
                    ></input>
                    <span className='Checkbox'></span>
                </label>

                <label className='max-w-[400px]'>
                    Raise PO
                </label>
            </div>

            {/* ===================================================== */}
            {/*  PO Request Information                               */}
            {/* ===================================================== */}

            {
                isRaisePOChecked &&
                <div className='Task-Section-Container'>

                    <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'poRequestCollapesed': !taskSection.poRequestCollapesed })}>
                        <img
                            className='cursor-pointer'
                            src={taskSection?.poRequestCollapesed ? Collapse : Expand}
                            alt={'>'}
                        ></img>
                        <h4 className='text-[20px] m-0'>PO Request Information</h4>
                    </div>
                    <hr className='m-0' hidden={taskSection?.poRequestCollapesed}></hr>
                    <div className='p-[3%] pt-1' hidden={taskSection?.poRequestCollapesed}>

                        {/* =========================================================== */}
                        {/*  Request Type                                               */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <label className='font-medium'>Request Type</label>
                            <input
                                className='Input-Field-Text'
                                style={{ width: '400px' }}
                                type='text'
                                value={task?.requesttype}
                                disabled
                            ></input>
                        </div>

                        {/* =========================================================== */}
                        {/*  Requester Name                                             */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <label className='font-medium'>Name of Requester</label>
                            <input
                                className='Input-Field-Text'
                                style={{ width: '400px' }}
                                type='text'
                                value={task?.requestername}
                                disabled
                            ></input>
                        </div>

                        {/* =========================================================== */}
                        {/*  Short Description of Request                               */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                styleInput={{ width: '100%' }}
                                type={'text'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.shortdescription'}
                                label={<label className='font-medium'>Short Description of Request <span className='text-[#C4314B]'>*</span></label>}
                                defaultValue={task?.potask?.shortdescription}
                                placeholder={'Enter a short description of the request'}
                                disabled={task?.status === 'Completed'}
                            ></LiveField>
                        </div>

                        {/* =========================================================== */}
                        {/*  Detailed Request / Scope Description                       */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                styleInput={{ width: '100%' }}
                                type={'multiline'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.commercialscope'}
                                label={<label className='font-medium'>Detailed Request / Scope Description<span className='text-[#C4314B]'>*</span></label>}
                                defaultValue={task?.potask?.commercialscope}
                                placeholder={'Enter a short description of the request'}
                                disabled={task?.status === 'Completed'}
                            ></LiveField>
                        </div>

                        {/* =========================================================== */}
                        {/*  Sponsor Name                                               */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'user-search'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.sponsorname'}
                                label={<label className='font-medium'>Sponsor Name<span className='text-[#C4314B]'>*</span></label>}
                                defaultValue={task?.potask?.sponsorname === undefined ? task?.sponsorname : task?.potask?.sponsorname}
                                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
                            ></LiveField>
                        </div>
                    </div>
                </div>
            }

            {/* ===================================================== */}
            {/*  Contract Information                                 */}
            {/* ===================================================== */}

            {
                isRaisePOChecked &&
                <div className='Task-Section-Container'>

                    <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'contractInformationCollapsed': !taskSection.contractInformationCollapsed })}>
                        <img
                            className='cursor-pointer'
                            src={taskSection?.contractInformationCollapsed ? Collapse : Expand}
                            alt={'>'}
                        ></img>
                        <h4 className='text-[20px] m-0'>Contract Information</h4>
                    </div>
                    <hr className='m-0' hidden={taskSection?.contractInformationCollapsed}></hr>
                    <div className='p-[3%] pt-1' hidden={taskSection?.contractInformationCollapsed}>

                        {/* =========================================================== */}
                        {/*  PoA Number                                                 */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <label className='font-medium'> PoA Number </label>
                            <input
                                className='Input-Field-Text'
                                style={{ width: '200px' }}
                                type='text'
                                value={task?.poanumber}
                                disabled
                            ></input>
                        </div>

                        {/* =========================================================== */}
                        {/*  Agreement Type                                             */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'choice'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.agreementtype'}
                                defaultValue={task?.potask?.agreementtype === undefined ? task?.agreementtype : task?.potask?.agreementtype}
                                disabled={task?.status === 'Completed'}
                                label={<label className='font-medium'> Agreement Type <span className='text-[#C4314B]'>*</span></label>}
                                options={[
                                    'Consultancy Services Agreement (CSA)',
                                    'Hardware Order',
                                    'Letter of Intent (LoI)',
                                    'Master Services Agreement (MSA)',
                                    'Non-Disclosure Agreement (NDA)',
                                    'Novation/Assignment Agreement',
                                    'Service Order (SO)',
                                    'Software License',
                                    'Subscription',
                                    'Supply Agreement',
                                    'Variation'
                                ]}
                                styleInput={{ width: '400px' }}
                            ></LiveField>
                        </div>

                        {/* =========================================================== */}
                        {/*  Vendor                                                     */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'lookup'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.vendor'}
                                label={<label className='font-medium'> Vendor <span className='text-[#C4314B]'>*</span></label>}
                                defaultValue={task?.potask?.vendor?.vendorname === undefined ? task?.vendor?.vendorname : task?.potask?.vendor?.vendorname}
                                placeholder={'Search vendor by name'}
                                dataFieldCollectionid={'vendors'}
                                dataFieldPrimary={'vendorname'}
                                dataFieldSecondary={'vendorid'}
                                dataFieldTertiary={'tradingas'}
                                disabled={task?.status === 'Completed'}
                                styleInput={{ width: '400px' }}
                            ></LiveField>

                            <div className='w-[450px] flex flex-row gap-2 px-[15px] py-[7px] rounded-md mx-1 my-1 bg-[#E9F7FF] border-1 border-[#0972D3]'>
                                <img src={Info} alt='info'></img>
                                <p className='mb-0'>To add a new vendor, click <a href={'https://inventory.store.lendlease.com/inventory?view=vendors'} target='_blank' rel='noopener noreferrer'>here</a>.</p>
                            </div>

                        </div>

                        {/* =========================================================== */}
                        {/*  Lendlease Company (signing entity)                         */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'text'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.lendleasecompany'}
                                defaultValue={task?.potask?.lendleasecompany === undefined ? task?.lendleasecompany : task?.potask?.lendleasecompany}
                                label={<label className='font-medium'> Lendlease Company (signing entity) <span className='text-[#C4314B]'>*</span></label>}
                                styleInput={{ width: '400px' }}
                                disabled={task?.status === 'Completed'}

                            ></LiveField>
                        </div>

                        {/* =========================================================== */}
                        {/*  Parent Contract                                            */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'text'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.parentcontract'}
                                defaultValue={task?.potask?.parentcontract}
                                label={<label className='font-medium'> Parent Contract </label>}
                                styleInput={{ width: '400px' }}
                                disabled={task?.status === 'Completed'}
                            ></LiveField>
                        </div>

                        {/* =========================================================== */}
                        {/*  Contract Start Date                                        */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'date-string'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.contractstartdate'}
                                defaultValue={task?.potask?.contractstartdate === undefined ? task?.contractstartdate : task?.potask?.contractstartdate}
                                label={<label className='font-medium'> Contract Start Date <span className='text-[#C4314B]'>*</span></label>}
                                styleInput={{ width: '400px' }}
                                disabled={task?.status === 'Completed'}
                                validation={{
                                    type: 'date',
                                    endDate: task?.potask?.contractenddate
                                }}
                            ></LiveField>
                        </div>

                        {/* =========================================================== */}
                        {/*  Contract End Date                                          */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'date-string'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.contractenddate'}
                                defaultValue={task?.potask?.contractenddate}
                                label={<label className='font-medium'> Contract End Date <span className='text-[#C4314B]'>*</span></label>}
                                styleInput={{ width: '400px' }}
                                disabled={task?.status === 'Completed'}
                                validation={{
                                    type: 'date',
                                    startDate: task?.potask?.contractstartdate === undefined ? task?.contractstartdate : task?.potask?.contractstartdate
                                }}
                            ></LiveField>
                        </div>

                        {/* =========================================================== */}
                        {/*  Document Storage Link                                      */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                styleInput={{ width: '100%' }}
                                type={'text'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.documentstoragelink'}
                                label={<label className='font-medium'> Document Storage Link <span className='text-[#C4314B]'>*</span></label>}
                                defaultValue={task?.potask?.documentstoragelink}
                                placeholder={'https://'}
                                disabled={task?.status === 'Completed'}
                                validation={{
                                    type: 'link'
                                }}
                            ></LiveField>
                        </div>

                    </div>
                </div>
            }

            {/* ===================================================== */}
            {/*  Financial Information                                */}
            {/* ===================================================== */}

            {
                isRaisePOChecked &&
                <div className='Task-Section-Container'>

                    <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'financialInformationCollapsed': !taskSection.financialInformationCollapsed })}>
                        <img
                            className='cursor-pointer'
                            src={taskSection?.financialInformationCollapsed ? Collapse : Expand}
                            alt={'>'}
                        ></img>
                        <h4 className='text-[20px] m-0'>Financial Information</h4>
                    </div>
                    <hr className='m-0' hidden={taskSection?.financialInformationCollapsed}></hr>
                    <div className='p-[3%] pt-1' hidden={taskSection?.financialInformationCollapsed}>

                        {/* =========================================================== */}
                        {/*  Currency                                                   */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'choice'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.currency'}
                                defaultValue={task?.potask?.currency === undefined ? task?.currency : task?.potask?.currency}
                                label={<label className='font-medium'> Currency </label>}
                                options={[
                                    'AUD',
                                    'SGD',
                                    'GBP',
                                    'USD'
                                ]}
                                styleInput={{ width: '400px' }}
                                disabled={task?.status === 'Completed'}
                            ></LiveField>
                        </div>

                        {/* =========================================================== */}
                        {/*  Estimated Annual Contract Value (exTax)                    */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'text'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                placeholder={'Enter Value'}
                                fieldpath={'potask.estannualcontractvalue'}
                                defaultValue={task?.potask?.estannualcontractvalue === undefined ? task?.estannualcontractvalue : task?.potask?.estannualcontractvalue}
                                label={<label className='font-medium'> Estimated Annual Contract Value (exTax) </label>}
                                styleInput={{ width: '400px' }}
                                disabled={task?.status === 'Completed'}
                            ></LiveField>
                        </div>

                        {/* =========================================================== */}
                        {/* Estimated Total Contract Value (exTax)                      */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'text'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.esttotalcontractvalue'}
                                placeholder={'Enter Value'}
                                defaultValue={task?.potask?.esttotalcontractvalue === undefined ? task?.esttotalcontractvalue : task?.potask?.esttotalcontractvalue}
                                label={<label className='font-medium'> Estimated Total Contract Value (exTax) </label>}
                                styleInput={{ width: '400px' }}
                                disabled={task?.status === 'Completed'}
                            ></LiveField>
                        </div>

                        {/* =========================================================== */}
                        {/* PO Number (eFinance)                                        */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>
                            <LiveField
                                type={'text'}
                                collectionid={'tasks'}
                                documentid={task?.taskid}
                                fieldpath={'potask.ponumber'}
                                defaultValue={task?.potask?.ponumber}
                                placeholder={'Enter PO Number'}
                                label={<label className='font-medium'> PO Number (eFinance) <span className='text-[#C4314B]'>*</span> </label>}
                                styleInput={{ width: '400px' }}
                                disabled={task?.status === 'Completed'}
                            ></LiveField>
                        </div>
                        <div className='FormComponent-Row'>
                            <label className='font-medium'> Setup PO Line Items </label>

                            {/*  PO Line Table */}

                            <div className='border border-[#D8D8D8] rounded'>
                                <table className='table-fixed w-full max-h-96 overflow-y-scroll'>
                                    <thead>
                                        <tr className='border-b-[#D8D8D8] border-solid border-b-2'>
                                            <th className='py-[15px] px-[20px] font-medium text-sm'>Project Code</th>
                                            <th className='py-[15px] px-[8px] font-medium text-sm'>Task Number</th>
                                            <th className='py-[15px] px-[8px] font-medium text-sm'>Expenditure Type</th>
                                            <th className='py-[15px] px-[20px] font-medium text-sm'>Currency</th>
                                            <th className='py-[15px] px-[20px] font-medium text-sm'>PO Amount (exTax)</th>
                                            <th className='py-[15px] px-[20px] font-medium text-sm'>Tax rate (%)</th>
                                            <th className='py-[15px] px-[8px] font-medium text-sm'>Fiscal Year</th>
                                            <th className='py-[15px] px-[8px] font-medium text-sm'>Description</th>
                                            <th className='py-[15px] px-[20px] font-medium text-sm'>LoA 1</th>
                                            <th className='py-[15px] px-[8px] font-medium text-sm'>Operating Unit</th>

                                            <th className='py-[15px] px-[15px] font-medium text-sm min-w-20'>
                                                <Tooltip
                                                    message={'+ Add PO item'}
                                                    children={
                                                        <img className='cursor-pointer' src={task?.status === 'Completed' || task?.stage !== 'Review and Negotiate' ? AddDisabled : Add} alt='add' onClick={() => task?.status === 'Completed' || task?.stage !== 'Review and Negotiate' ? null : setAddPOLineItemPaneOpen(true)}></img>
                                                    }
                                                ></Tooltip>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            // No PO Line Items Found
                                            task?.potask?.polineitems?.length === 0 || task?.potask?.polineitems === undefined
                                                ?
                                                (
                                                    <tr>
                                                        <td colSpan={10}>
                                                            <div className='text-center p-[2%]'>
                                                                Click on the '+' icon to add a new PO Line Item.
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                (

                                                    // Map in each PO Line Item
                                                    task?.potask?.polineitems?.map((poLine, index) => (
                                                        <tr
                                                            key={index}
                                                            className='border-b-[#C8C8C9] border-solid border-b last:border-0 hover:bg-[#F0F7F7]'
                                                        >

                                                            {/* Project Code */}
                                                            <td className='py-[15px] px-[20px] text-sm'>{poLine?.projectcode}</td>

                                                            {/* Task Number */}
                                                            <td className='py-[15px] px-[8px] text-sm'>
                                                                {poLine?.tasknumber}
                                                            </td>

                                                            {/* Expenditure Type */}
                                                            <td className='py-[15px] px-[8px] text-sm'>{poLine?.expendituretype}</td>

                                                            {/* Currency */}
                                                            <td className='py-[15px] px-[20px] text-sm'>{poLine?.currency}</td>

                                                            {/* PO Amount */}
                                                            <td className='py-[15px] px-[20px] text-sm'>{poLine?.poamount}</td>

                                                            {/* Tax Rate */}
                                                            <td className='py-[15px] px-[20px] text-sm'>{poLine?.taxrate}</td>

                                                            {/* Fiscal Year */}
                                                            <td className='py-[15px] px-[8px] text-sm'>{poLine?.fiscalyear}</td>

                                                            {/* Description */}
                                                            <td className='py-[15px] px-[8px] text-sm'>{poLine?.description}</td>

                                                            {/* LoA 1 */}
                                                            <td className='py-[15px] px-[20px] text-sm'>{poLine?.loa1}</td>

                                                            {/* Operating Unit */}
                                                            <td className='py-[15px] px-[8px] text-sm'>{poLine?.operatingunit}</td>

                                                            {/* Dropdown with Edit and Remove options */}
                                                            {
                                                                task?.stage !== 'Review and Negotiate' || task?.status === 'Completed' ?
                                                                    <td className='py-[15px] px-[8px] text-sm'></td>
                                                                    :
                                                                    <td className='py-[15px] px-[8px] text-sm'>
                                                                        <KebabDropdown>
                                                                            <div className=' bg-white  rounded shadow-lg z-10'>
                                                                                <button
                                                                                    onClick={() => handlePOItemEditClick(index)}
                                                                                    className='block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100'
                                                                                >
                                                                                    Edit
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => handlePOItemDeleteClick(index)}
                                                                                    className='block w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100'
                                                                                >
                                                                                    Remove
                                                                                </button>
                                                                            </div>
                                                                        </KebabDropdown>
                                                                    </td>
                                                            }
                                                        </tr>
                                                    ))
                                                )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* ===================================================== */}
            {/*  Execution not required Checkbox                      */}
            {/* ===================================================== */}

            <div className='flex flex-row items-start gap-2 mb-2 mt-2'>
                <label className='Checkbox-Container'>
                    <input
                        required
                        type='checkbox'
                        name='confirmation'
                        checked={isExecutionRequired}
                        onChange={() => handleExecutionNotRequired(!isExecutionRequired)}
                        disabled={task?.status === 'Completed' || task?.stage !== 'Review and Negotiate'}
                    ></input>
                    <span className='Checkbox'></span>
                </label>

                <label className='max-w-[400px]'>
                    Execution not required
                </label>
            </div>

            {/* ===================================================== */}
            {/*  Move to Next Stage Button                            */}
            {/* ===================================================== */}

            <div className='flex flex-col gap-1 mt-4 mb-0'>

                <button
                    className='Primary-Button w-fit'
                    onClick={() => moveToNextStage()}
                    disabled={
                        task?.stage !== 'Review and Negotiate' ||
                        approvalData?.firstRoundApprovals.filter((item) => item?.status === 'In Progress')?.length > 0 ||
                        approvalData?.secondRoundApprovals?.filter((item) => item?.status === 'In Progress')?.length > 0 ||
                        (
                            isRaisePOChecked && (
                                !task?.potask?.shortdescription?.trim() ||         // Check if short description is empty or only whitespace
                                !task?.potask?.commercialscope?.length ||          // Check if commercial scope is empty
                                !isSponsorEmailValid ||                            // Check if sponsor name is empty
                                !isAgreementTypeValid ||                           // Check if agreement type is empty
                                !isVendorIdValid ||                                // Check if vendor ID is empty
                                !isContractStartDateValid ||                       // Check if contract start date is missing
                                !task?.potask?.contractenddate ||                  // Check if contract end date is missing
                                !task?.potask?.ponumber ||                         // Check if ponumber is empty
                                !isLendleaseCompanyValid ||                        // Check if lendleasecompany is empty
                                !task?.potask?.documentstoragelink?.length         // Check if document storage link is empty
                            )
                        )
                    }
                >
                    Proceed to Next Stage
                </button>

                <label className='text-[14px] text-slate-600 italic my-2'>If “Raise PO” has been selected, a PO request with the above information will be sent to the procurement team.</label>

            </div>

            {/* ===================================================== */}
            {/*  Add Approver Pane                                    */}
            {/* ===================================================== */}

            <AddApproverPane
                task={task}
                addApproverPaneOpen={addApproverPaneOpen}
                setAddApproverPaneOpen={setAddApproverPaneOpen}
            ></AddApproverPane>

            {/* ===================================================== */}
            {/*  View Approver Pane                                   */}
            {/* ===================================================== */}

            <ViewApproverPane
                task={task}
                viewPaneApprover={viewPaneApprover}
                setViewPaneApprover={setViewPaneApprover}
            ></ViewApproverPane>

            {/* ===================================================== */}
            {/*  Cancel Approval Modal                                */}
            {/* ===================================================== */}

            <CancelApprovalModal
                cancelModalApprover={cancelModalApprover}
                setCancelModalApprover={setCancelModalApprover}
            ></CancelApprovalModal>

            {/* ===================================================== */}
            {/*  Add PO Line Item Pane                                */}
            {/* ===================================================== */}

            <AddPOLineItemPane
                task={task}
                addPOLineItemPaneOpen={addPOLineItemPaneOpen}
                setAddPOLineItemPaneOpen={setAddPOLineItemPaneOpen}
            ></AddPOLineItemPane>

            {/* ===================================================== */}
            {/*  Edit PO Line Item Pane                               */}
            {/* ===================================================== */}

            {
                editPOLineItemPaneOpen &&
                (
                    <EditPOLineItemPane
                        task={task}
                        editPOLineItemPaneOpen={editPOLineItemPaneOpen}
                        setEditPOLineItemPaneOpen={setEditPOLineItemPaneOpen}
                        polineitemIndex={selectedPOLineItemIndex}
                    ></EditPOLineItemPane>
                )
            }

        </div >
    )

}
