//================================================================
//  Component: Add Approver Pane
//================================================================

//  Purpose: This pane will allow users to request approval from approvers across different roles

//  Properties:
//    - task = {An object, contains the commercial task details}
//    - addApproverPaneOpen = {useState, used to determine if the pane is visible}
//    - setAddApproverPaneOpen = {useState, used to toggle the visibility of the add approver pane}

//  Example:
//    <AddApproverPane
//      task={task}
//      addApproverPaneOpen={addApproverPaneOpen}
//      setAddApproverPaneOpen={setAddApproverPaneOpen}
//    ></AddApproverPane>    

//================================================================


//Libraries
import React, { useContext, useState, useReducer, useRef } from 'react';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Components
import UserSearch from '../../../../Components/UserSearch/UserSearch';

//Functions
import WriteDocument from '../../../../Library/WriteDocument';
import UploadFile from '../../../../Library/UploadFile';

//Images
import ClearDisabled from '../../../../Components/Images/Icon_ClearDisabled_Grey.svg';
import Clear from '../../../../Components/Images/Icon_Clear_Red.svg';
import Error from '../../../../Components/Images/Icon_ErrorFatal_Red.svg';
import Loading from '../../../../Components/Images/Image_Loading_Ripple.svg';

export default function AddApproverPane({
  task,
  addApproverPaneOpen, 
  setAddApproverPaneOpen
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useRef
  //------------------------------------------------------

    //For storing file input value
    const fileInputRef = useRef(null);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to set pane status > 'onload', 'pending', 'error'
    const [paneStatus, setPaneStatus] = useState('onload');

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store the form inputs
    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'newUserInput': '',
        'newUserGivenName': '',
        'newUserSurname': '',
        'newApproverRole': '',
        'approverErrorMessage': '',
        'approvers': [],
        'subject': '',
        'subjectErrorMessage': '',
        'body': '',
        'bodyErrorMessage': '',
        'attachedFile': undefined,
        'attachedFileErrorMessage': '',
        'formErrorMessage': ''
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Adds an approver to 'approvers'
    function addApprover() { 

      // Check for duplicates
      if (formData.approvers.filter((object) => object.approveremail === formData.newUserInput).length > 0) {

        return setFormData({
          'approverErrorMessage': 'This approver already exists.'
        });

      }

      // Update Form Data
      formData.approvers.push({
        'approveremail': formData.newUserInput,
        'approvergivenname': formData.newUserGivenName,
        'approversurname': formData.newUserSurname,
        'approverrole': formData.newApproverRole,
      })
      formData.newUserInput = '';
      formData.newUserGivenName = '';
      formData.newUserSurname = '';
      formData.newApproverRole = '';
      formData.approverErrorMessage = '';

      setFormData(formData);

    };

    // Submit Handler
    function handleSubmit() {

      // ----------------------------------------------
      //  Validation Checks
      // ----------------------------------------------

      // OnSubmit Validation of all required fields
      let preventSubmit = false;

      // Check new approver fields
      if(formData.newUserInput.length > 0) {

        if (formData.newApproverRole.length === 0) {
          preventSubmit = true;
          formData.approverErrorMessage = 'Please select a role.'

        } else {

          formData.approvers.push({
            'approveremail': formData.newUserInput,
            'approvergivenname': formData.newUserGivenName,
            'approversurname': formData.newUserSurname,
            'approverrole': formData.newApproverRole
          });
          formData.approverErrorMessage = '';

        }

      } else {
        formData.signeeErrorMessage = '';

      }

      // Field Validation | Approvers
      if (formData.approvers.length === 0 && (formData.newUserInput.length === 0 || formData.newApproverRole.length === 0)) {
        preventSubmit = true;
        formData.approverErrorMessage = 'This field is required. Please add at least one approver.';

      } else {
        formData.approverErrorMessage = '';

      };

      // Field Validation | Subject
      if (formData.subject.length === 0) {
        preventSubmit = true;
        formData.subjectErrorMessage = 'This field is required.';

      } else {
        formData.subjectErrorMessage = '';

      };

      // Field Validation | Body
      if (formData.body.length === 0) {
        preventSubmit = true;
        formData.bodyErrorMessage = 'This field is required.';

      } else {
        formData.bodyErrorMessage = '';

      };

      setFormData(formData);

      // Check if ALL validations have passed
      if (preventSubmit === true) return;

      setPaneStatus('pending');

      // ----------------------------------------------
      //  Upload files
      // ----------------------------------------------

      const filePromises = [];

      // Create/extract file ID & type
      const fileType = formData?.attachedFile?.name?.split('.')[1];
      const fileId = `${Date.now().toString()}${Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)}.${fileType}`;

      // Loop through each approver and update document
      formData.approvers?.forEach((approver) => {

        // Define a taskid
        const taskId = `task-${Date.now().toString()}${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`;

        // Save the task id into the approver object
        approver.taskid = taskId;

        // No file attached > Return an empty string
        if (formData.attachedFile === undefined) return filePromises.push('');
        
        // Request signed url
        filePromises.push(
          UploadFile(`tasks/${taskId}/${fileId}`, formData.attachedFile),
        );

      });

      // ----------------------------------------------
      //  Settle Promises > Write to Firestore
      // ----------------------------------------------

      Promise.all(filePromises)
      .then((urls) => {

        const writeDocPromises = [];

        // Loop through each approver and update document
        formData.approvers?.forEach((approver, index) => {
        
          const approvalTask = {
            'taskid': approver?.taskid,
            'requestid': task?.requestid,
            'tasktype': 'Commercial Second Round Approval',
            'taskname': `${approver?.approverrole} for ${task?.applicationname}`,
            'taskdescription': formData?.body,
            'applicationname': task?.applicationname,
            'stage': '', 
            'status': 'In Progress',
            'assignmentgroup': 'Commercial',
            'requesttype': '',
            'activityfeedvisible': false, 
            'approvalhubvisible': true,
            'assignedto': {
                'email': approver?.approveremail, 
                'givenname': approver?.approvergivenname,
                'surname': approver?.approversurname
            }, 
            'assigneddate': new Date(),
            'createddate': new Date(),       
            'createdby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'activityfeed': [
              {
                  'activitydate': new Date(),                           
                  'actionedby': {
                    'email': getUser?.emailaddress,
                    'givenname': getUser?.givenname,
                    'surname': getUser?.surname  
                  },
                  'action': 'created',
                  'comments': '',
              },
              {
                  'activitydate': new Date(),                           
                  'actionedby': {
                    'email': approver?.approveremail,
                    'givenname': approver?.approvergivenname,
                    'surname': approver?.approversurname  
                  },
                  'action': 'assigned',
                  'comments': '',
              }
            ],
            'lastmodifieddate': new Date(),  
            'lastmodifiedby': {
              'email': getUser?.emailaddress,
              'givenname': getUser?.givenname,
              'surname': getUser?.surname
            }, 

            // Approval Fields
            'emailsubject': formData?.subject,
            'emailbody': formData?.body,
            'supportingdocs': urls[index].length > 0 ?
              [
                {
                  'fileUrl': urls[index], // Extract the signed url from the array of promises
                  'fileId': fileId,
                  'fileName': formData?.attachedFile?.name,
                  'fileType': fileType,
                }
              ]
              :
              []
            ,
            'approverrole': approver?.approverrole
          }

          writeDocPromises.push(
            WriteDocument('tasks', approver?.taskid, approvalTask, false),
          );

        });

        return Promise.all(writeDocPromises)
        .then(() => {

          // Reset useStates
          setFormData({
            'newUserInput': '',
            'newUserGivenName': '',
            'newUserSurname': '',
            'newApproverRole': '',
            'approverErrorMessage': '',
            'approvers': [],
            'subject': '',
            'subjectErrorMessage': '',
            'body': '',
            'bodyErrorMessage': '',
            'documents': [],
            'documentsErrorMessage': '',
            'formErrorMessage': ''
          })

          // Reset Status
          setPaneStatus('onload');

          // Close Pane
          setAddApproverPaneOpen(false);

        })

      })
      .catch((errors) => {

        console.log(errors);
        setPaneStatus('error');

      })

    }
  
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------
    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    if (!addApproverPaneOpen) return null;

    // ---------------------------------------------------
    //  Onload
    // ---------------------------------------------------

    if (paneStatus === 'onload') {
      return (
        <div className='Pane-Background'>
          <dialog className='Pane-Container'>

            <div className='flex flex-col gap-3 justify-between'>

              {/* ------------------------------- */}
              {/*  Approver Form                  */}
              {/* ------------------------------- */}
              
              <div className='Pane-Form-Container'>
      
                {/* ===================================== */}
                {/*  Header                               */}
                {/* ===================================== */}
                
                <h4 className='px-[5%] py-[2.5%] mb-0 text-[22px]'> Add Approver </h4>
                <hr className='m-0'></hr>

                <label className='px-[5%] pt-[4%] pb-[2%]'>
                  Additional approvers added to this request will be notified via email.
                </label>

                {/* ===================================== */}
                {/*  Form                                 */}
                {/* ===================================== */}

                <div className='flex flex-col gap-2 px-[5%]'>

                  {/* ------------------------------- */}
                  {/*  Approvers List                 */}
                  {/* ------------------------------- */}

                  {
                    formData?.approvers.map((approver, index) => (
                      <div className='grid grid-cols-2' key={index}>
                        
                        {/*  Approver Email */}
                        <div className='FormComponent-Row'>
                          <label hidden={index > 0} className='font-medium'> Approver Email * </label>
                          <input
                            className='Input-Field-Text'
                            style={{width: '100%'}}
                            type='text'
                            placeholder='Enter email address'
                            value={approver?.approveremail}
                            disabled
                          ></input>
                        </div>

                        {/* Role */}
                        <div className='flex flex-row gap-2 items-end'>
                          <div className='FormComponent-Row'>
                            <label hidden={index > 0} className='font-medium'> Role * </label>
                            <select className='Input-Field-Select' style={{ width: '250px' }} disabled>
                              <option>{approver?.approverrole}</option>                                 
                            </select>
                          </div>

                          {/* Clear Button */}
                          <div className='pb-2 cursor-pointer'>
                            <img
                              src={Clear} 
                              alt='clear' 
                              onClick={() => {
                                formData.approvers?.splice(index, 1);
                                setFormData(formData);
                              }
                            }></img>
                          </div>

                        </div>
                      </div>
                    ))
                  }

                  {/* ------------------------------- */}
                  {/*  + Add Approver                 */}
                  {/* ------------------------------- */}

                  <div className='grid grid-cols-2'>
                  
                    {/*  Approver Email */}
                    <div className='FormComponent-Row'>
                      <label hidden={formData.approvers.length > 0} className='font-medium'> Approver Email * </label>
                      <UserSearch
                        selectedUser={formData}
                        setSelectedUser={setFormData}
                      ></UserSearch>
                    </div>

                    {/* Role */}
                    <div className='flex flex-row gap-2 items-end'>
                      <div className='FormComponent-Row'>
                        <label hidden={formData.approvers.length > 0} className='font-medium'> Role * </label>
                        <select 
                          className='Input-Field-Select'
                          style={{ width: '250px' }}
                          onChange={(e) => { setFormData({ 'newApproverRole': e.target.value })}} 
                          value={formData.newApproverRole}
                        >
                          <option hidden value=''>-</option>
                          <option value='Legal'>Legal</option>
                          <option value='Risk'>Risk</option>
                          <option value='Cyber'>Cyber</option>
                          <option value='Finance'>Finance</option>
                          <option value='LoA'>LoA</option>                                   
                        </select>
                      </div>

                      {/* Clear Button */}
                      <div className='pb-2 cursor-pointer'>
                        {
                          formData?.newUserInput.length > 0 || formData?.newApproverRole.length > 0 ?
                          <img src={Clear} alt='clear' onClick={() => {
                            formData.newUserInput = '';
                            formData.newApproverRole = '';
                            formData.approverErrorMessage = '';
                            setFormData(formData);
                          }}></img>
                          :
                          <img src={ClearDisabled} alt='clear'></img>
                        }
                      </div>

                    </div>

                  </div>

                  {/* Error Message */}
                  <label className='font-medium text-[#DE0000] ml-[5px]' hidden={formData?.approverErrorMessage.length === 0}>{formData?.approverErrorMessage}</label>

                  {/* Add Approver Button */}
                  <div>
                    <button 
                      className='Secondary-Button' 
                      style={{ marginLeft: '5px'}}
                      onClick={() => addApprover()}
                      disabled={formData.newUserInput.length === 0 || formData.newApproverRole.length === 0}
                    >
                      <strong>+</strong> Add
                    </button>
                  </div>
                                      
                  {/* ------------------------------- */}
                  {/*  Email Subject                  */}
                  {/* ------------------------------- */}

                  <div className='FormComponent-Row'>
                    <label className='font-medium'>Email Subject * </label>
                    <input
                        className={formData.subjectErrorMessage.length > 0 ? 'Input-Field-Text-Error': 'Input-Field-Text'}
                        type='text'
                        placeholder='Enter email subject'
                        value={formData?.subject}
                        onChange={(e) => setFormData({ 'subject': e.target.value })}
                    ></input>

                    <label className='font-medium text-[#DE0000] mb-0' hidden={formData?.subjectErrorMessage.length === 0}>{formData?.subjectErrorMessage}</label>
                  </div>

                  {/* ------------------------------- */}
                  {/*  Email Body                     */}
                  {/* ------------------------------- */}

                  <div className='FormComponent-Row'>
                    <label className='font-medium'> Email Body * </label>
                    <textarea
                        className={formData.bodyErrorMessage.length > 0 ? 'Input-Field-TextArea-Error' : 'Input-Field-TextArea'}
                        style={{height: '200px'}}
                        type='text'
                        placeholder='Enter email body'
                        onChange={(e) => setFormData({ 'body': e.target.value })}
                        value={formData?.body}
                    ></textarea>

                    <label className='font-medium text-[#DE0000] mb-0' hidden={formData?.bodyErrorMessage.length === 0}>{formData?.bodyErrorMessage}</label>
                  </div>

                  {/* ------------------------------- */}
                  {/*  Document                       */}
                  {/* ------------------------------- */}

                  <div className='FormComponent-Row mb-[3%]'>
                    <label className='font-medium mb-1'> Attach Document </label>
                    
                    <div className='Attach-File-Onload-Container'>
                      
                      {/* File Input Field */}
                      <input
                        className={formData?.attachedFileErrorMessage?.length > 0 ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                        style={{padding: '0px'}}
                        type='file'
                        accept='.pdf,.doc,.txt,.xlsx,.docx,.csv,.png,.jpg'
                        ref={fileInputRef}
                        onChange={(evt) => {

                          const fileObject = evt.target.files[0];
                            
                          // Validate File Types
                          const allowedFileTypes = ['pdf', 'doc', 'txt', 'xlsx', 'docx', 'csv', 'png', 'jpg'];

                          let fileType;
                          fileType = fileObject?.name.split('.');
                          fileType = fileType[fileType?.length - 1].toLowerCase();

                          if (allowedFileTypes.includes(fileType) === false) {
                            return setFormData({ 'attachedFileErrorMessage': `Please upload a file in the following format(s): ${allowedFileTypes.join(', ')}.` });
                  
                          }

                          // Validate File Size < 20 MB
                          if (fileObject.size > 20000000) {
                            return setFormData({ 'attachedFileErrorMessage': 'File size too large. Please upload a file smaller than 20MB.' });

                          }

                          setFormData({
                            'attachedFile': fileObject,
                            'attachedFileErrorMessage': ''
                          });

                        }}
                      ></input>

                      {/* Clear Button */}
                      <button className='Primary-Button' disabled={formData?.attachedFile === undefined} onClick={() => {

                        // Clear File Input Field
                        if (fileInputRef.current) {

                          fileInputRef.current.value = '';
                          setFormData({
                            'attachedFile': undefined,
                            'attachedFileErrorMessage': ''
                          });

                        }
                      }}>
                        Clear
                      </button>

                    </div>

                    {/* Error Message */}
                    <label className='font-medium text-[#DE0000]' hidden={formData?.attachedFileErrorMessage.length === 0}>{formData?.attachedFileErrorMessage}</label>

                  </div>

                </div>

              </div>

              {/* ------------------------------- */}
              {/*  Submit Buttons                 */}
              {/* ------------------------------- */}
              
              <div className='flex flex-row gap-2 justify-self-end'>
                <button 
                  className='Primary-Button' 
                  onClick={() => handleSubmit()}
                  disabled={formData.subject.length === 0 || formData.body.length === 0 || (formData.approvers.length === 0 && (formData.newUserInput.length === 0 || formData.newApproverRole.length === 0)) }
                > 
                  Submit 
                </button>
                <button className='Secondary-Button' onClick={() => {
                  setFormData({
                    'newUserInput': '',
                    'newUserGivenName': '',
                    'newUserSurname': '',                    
                    'newApproverRole': '',
                    'approverErrorMessage': '',
                    'approvers': [],
                    'subject': '',
                    'subjectErrorMessage': '',
                    'body': '',
                    'bodyErrorMessage': '',
                    'documents': [],
                    'documentsErrorMessage': '',
                    'formErrorMessage': ''
                  });
                  setAddApproverPaneOpen(false)
                }}> 
                  Cancel
                </button>
              </div>

            </div>
                            
          </dialog>
        </div>
      )
    }

    // ---------------------------------------------------
    //  Pending
    // ---------------------------------------------------

    else if (paneStatus === 'pending') {
      return (
        <div className='Pane-Background'>
          <dialog className='Pane-Container flex justify-center items-center text-center'>
            <img alt='loading-circle-icon' src={Loading}></img>
          </dialog>
        </div>
      )
    }

    // ---------------------------------------------------
    //  Error
    // ---------------------------------------------------

    else if (paneStatus === 'error') {
      return (
        <div className='Pane-Background'>
          
          {/* Pane Container */}
          <dialog className='Pane-Container'>
            <div className='flex flex-col gap-2 w-full justify-center items-center'>

              <img className='my-2 w-[100px]' src={Error} alt='Error'></img> 
              <h4 className='mb-0'>Oops! Something went wrong.</h4>
              <p className='text-center leading-[1.7]'>
                An error occurred while we processed your request.
              </p>
              <button 
                className='Primary-Button' 
                onClick={() => {
                  setFormData({
                    'newUserInput': '',
                    'newUserGivenName': '',
                    'newUserSurname': '',                    
                    'newApproverRole': '',
                    'approverErrorMessage': '',
                    'approvers': [],
                    'subject': '',
                    'subjectErrorMessage': '',
                    'body': '',
                    'bodyErrorMessage': '',
                    'documents': [],
                    'documentsErrorMessage': '',
                    'formErrorMessage': ''
                  })
                  setPaneStatus('onload');
                  setAddApproverPaneOpen(false);
                }}
              >
                Close
              </button>
            
            </div>
          </dialog>
        </div>

      )
    }

  //------------------------------------------------------
}
