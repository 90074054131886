//================================================================
//  Component: RequestPane
//================================================================

//  Purpose: This side pane allows a user to view a digital commercial request

//  Properties:
//    - paneOpen = { useState, holds a boolean value that opens and closes the sidePane}
//    - setPaneOpen = { useState, updates the boolean value that opens and closes the sidePane}
//    - selectedRequest = { useState, stores the currently selected request}
//    - setSelectedRequest = { useState, used to set the selected request }

//  Example:
//  
//    <RequestPane
//      paneOpen={openPane}
//      setPaneOpen={setOpenPane}
//      selectedRequest={selectedRequest}
//      setSelectedRequest={setSelectedRequest}
//    ></RequestPane>

//================================================================

// Libraries
import React from 'react';

// Contexts

// Functions

// Images
import FileIcon from '../Images/Icon_File_Black.svg';
import InfoRequired from '../Images/Icon_InfoRequired_Blue.svg';

export default function RequestPane({
    paneOpen,
    setPaneOpen,
    selectedRequest,
    setSelectedRequest
}) {

    // --------------------------------------------------
    //  HTML
    // --------------------------------------------------

        // ==================================================
        //  Hide Side Pane
        // ==================================================

        if (!paneOpen) return null;

        // ==================================================
        //  Show Side Pane  
        // ==================================================

        return (
            <div className='Pane-Background'>  
                <dialog className='Pane-Container'>
                    <div className='flex flex-col gap-3 justify-between'>

                        {/* ===================================== */}
                        {/*  Digital Commercial Request Form      */}
                        {/* ===================================== */}

                        <div className='Request-Form-Container'>

                            {/* ===================================== */}
                            {/*  Header                               */}
                            {/* ===================================== */}
                            
                            <h4 className='px-[5%] py-[2.5%] mb-0 text-[20px]'> Digital Commercial Request | {selectedRequest?.poanumber.toUpperCase()} </h4>
                            <hr className='m-0'></hr>

                            {/* ===================================== */}
                            {/*  Form                                 */}
                            {/* ===================================== */}

                            <div className='px-[5%] py-[4%]'>

                                {/* ------------- REQUEST INFORMATION ------------- */}

                                <h5 className='text-[18px] text-teal-600 mb-2'>Request Information</h5>

                                {/* =========================================================== */}
                                {/*  1. Request Type                                            */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 1. Request Type </label>
                                    <input
                                        className='Input-Field-Text'
                                        type='text'
                                        value={selectedRequest?.requesttype}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  2. Application Name                                        */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 2. Application Name </label>
                                    <input
                                        className='Input-Field-Text'
                                        type='text'
                                        value={selectedRequest?.applicationname}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  3. Short Description                                       */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 3. Short Description </label>
                                    <textarea
                                        className='Input-Field-TextArea'
                                        type='text'
                                        value={selectedRequest?.shortdescription}
                                        disabled
                                    ></textarea>
                                </div>

                                {/* =========================================================== */}
                                {/*  4. Requester Name                                          */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 4. Requester Name </label>
                                    <input
                                        className='Input-Field-Text'
                                        type='text'
                                        value={selectedRequest?.requestername}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  5. Region                                                  */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 5. Region </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{width: '200px'}}
                                        type='text'
                                        value={selectedRequest?.region}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  6. Sponsor Name                                            */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 6. Sponsor Name </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.sponsorname.email}</option>
                                    </select>
                                </div>

                                {/* =========================================================== */}
                                {/*  7. Created By                                              */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 7. Created By </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{width: '300px'}}
                                        type='text'
                                        value={selectedRequest?.createdby?.email}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  8. Supporting Documents                                    */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 8. Supporting Documents </label>
                                    {
                                        selectedRequest?.supportingdocs.length === 0 ?
                                        (
                                            <div>
                                                No documents found.
                                            </div>
                                        )
                                        :
                                        (
                                            <table className='table-auto w-full border-separate border-spacing-0 border-1 border-solid border-[#D8D8D8] rounded-[10px]'>
                                                <thead>
                                                    <tr>
                                                        <th className='font-normal px-3 py-2'>Uploaded Documents</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        selectedRequest?.supportingdocs.map((file, index) => (
                                                            <tr key={index}>
                                                                <td className='flex flex-row gap-2 px-3 py-2 text-[#0972D3] underline border-t border-t-[#D8D8D8]'>
                                                                    <img src={FileIcon} alt='file'></img>
                                                                    <a href={file?.fileUrl} target='_blank' rel='noreferrer'>
                                                                        {file?.fileName}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        )
                                    }
                                </div>

                                {/* ------------- CONTRACT INFORMATION ------------- */}

                                <h5 className='text-[18px] text-teal-600 mt-4 mb-2'>Contract Information</h5>

                                {/* =========================================================== */}
                                {/*  9. Commercial Scope                                        */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium mb-0'> 9. Commercial Scope Description </label>
                                    <textarea
                                        className='Input-Field-TextArea'
                                        value={selectedRequest?.commercialscope}
                                        disabled
                                    ></textarea>
                                </div>

                                {/* =========================================================== */}
                                {/*  10. Buy / Sell Agreement                                   */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 10. Is this a buy or sell agreement? </label>
                                    <select className='Input-Field-Select' style={{width: '200px'}} disabled>
                                        <option>{selectedRequest?.buysellagreement}</option>
                                    </select>
                                </div>

                                {/* =========================================================== */}
                                {/*  11. Agreement Type                                         */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 11. Agreement Type </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.agreementtype}</option>
                                    </select>
                                </div>

                                {/* =========================================================== */}
                                {/*  12. Contract Start Date                                    */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 12. Contract Start Date </label>
                                    <input
                                        className='Input-Field-Text'
                                        type='text'
                                        value={selectedRequest?.contractstartdate}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  13. Lendlease Company                                      */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 13. Lendlease Company (Signing Entity) </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.lendleasecompany}</option>
                                    </select>
                                </div>

                                {/* ------------- OTHER PARTY INFORMATION (VENDOR/CUSTOMER) ------------- */}

                                <h5 className='text-[18px] text-teal-600 mt-4 mb-2'> Other Party Information (Vendor / Customer) </h5>

                                {/* =========================================================== */}
                                {/*  14. Vendor Name                                            */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 14. Vendor Name </label>
                                    {
                                        selectedRequest?.newvendorname?.length === 0 ?

                                        // Existing Vendor
                                        <input
                                            className='Input-Field-Text'
                                            type='text'
                                            value={selectedRequest?.vendor?.vendorname}
                                            disabled
                                        ></input>

                                        :

                                        // New Vendor
                                        <div className='flex flex-col justify-center bg-[#E7F0F4] px-4 py-3 rounded-md'>
                
                                            {/* Header */}
                                            <div className='flex flex-row gap-2 items-center'>
                                                <img src={InfoRequired} alt='info'></img>
                                                <label className='font-medium text-[#5E93AC]'>New Vendor Request</label>
                                            </div>

                                            {/* Form */}
                                            <div className='grid grid-cols-2 gap-2'>

                                                {/* Column 1 */}
                                                <div className='flex flex-col gap-2 px-2 mt-0 mb-2'>
                            
                                                    {/* Vendor Name */}
                                                    <div className='Task-Form-Row'>
                                                        <label className='font-medium'> Vendor Name </label>
                                                        <input
                                                            className='Input-Field-Text'
                                                            style={{ width: '100%' }}
                                                            value={selectedRequest?.newvendorname}
                                                            type='text'
                                                            disabled
                                                        ></input>
                                                    </div>
                            
                                                    {/* Vendor Phone */}
                                                    <div className='Task-Form-Row'>
                                                        <label className='font-medium'> Vendor Name </label>
                                                        <input
                                                            className='Input-Field-Text'
                                                            style={{ width: '100%' }}
                                                            value={selectedRequest?.newvendorcontactphone}
                                                            type='text'
                                                            disabled
                                                        ></input>
                                                    </div>
                            
                                                </div>
                            
                                                {/* Column 2 */}
                                                <div className='flex flex-col gap-2 px-2 mt-0 mb-2'>
                            
                                                    {/* Vendor Trading As Name */}
                                                    <div className='Task-Form-Row'>
                                                        <label className='font-medium'> Vendor Trading As Name </label>
                                                        <input
                                                            className='Input-Field-Text'
                                                            style={{ width: '100%' }}
                                                            value={selectedRequest?.newvendortradingname}
                                                            type='text'
                                                            disabled
                                                        ></input>
                                                    </div>
                            
                                                    {/* Vendor Email */}
                                                    <div className='Task-Form-Row'>
                                                        <label className='font-medium'> Vendor Email </label>
                                                        <input
                                                            className='Input-Field-Text'
                                                            style={{ width: '100%' }}
                                                            value={selectedRequest?.newvendorcontactemail}
                                                            type='text'
                                                            disabled
                                                        ></input>
                                                    </div>
                            
                                                </div>

                                            </div>

                                        </div>
                                    }
                                </div>

                                {/* ------------- FINANCIAL INFORMATION ------------- */}

                                <h5 className='text-[18px] text-teal-600 mt-4 mb-2'> Financial Information </h5>

                                {/* =========================================================== */}
                                {/*  15. Currency                                               */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 15. Currency </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.currency}</option>
                                    </select>
                                </div>

                                {/* =========================================================== */}
                                {/*  16. Estimated Annual Contract Value (eTax)                 */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 16. Estimated Annual Contract Value (eTax) </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{width: '300px'}}
                                        type='text'
                                        value={selectedRequest?.estannualcontractvalue}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  17. Estimated Total Contract Value (eTax)                  */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 17. Estimated Total Contract Value (eTax) </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{width: '300px'}}
                                        type='text'
                                        value={selectedRequest?.esttotalcontractvalue}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  18. Project Code                                           */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 18. Project Code </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{width: '200px'}}
                                        type='text'
                                        value={selectedRequest?.projectcode}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  19. Task Number                                            */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 19. Task Number </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{width: '200px'}}
                                        type='text'
                                        value={selectedRequest?.tasknumber}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  20. Expenditure Type                                       */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 20. Expenditure Type </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{width: '200px'}}
                                        type='text'
                                        value={selectedRequest?.expendituretype}
                                        disabled
                                    ></input>
                                </div>

                            </div>

                        </div>

                        {/* ===================================== */}
                        {/*  Close Button                         */}
                        {/* ===================================== */}

                        <div className='flex flex-row gap-2'>
                            <button className='Primary-Button' onClick={() => {

                                // Reset Selected Request
                                setSelectedRequest();

                                // Close Side Pane
                                setPaneOpen(false);
                                
                            }}>
                                Close
                            </button>
                        </div>

                    </div>
                </dialog>
            </div>
        );
}
