//================================================================
//  Component: Activity Feed
//================================================================

//  Purpose: This is the task activity feed

//  Properties:
//    - task = {An object, contains the current commercial task}

//  Example:
//    <ActivityFeed
//      task={task}
//    ></ActivityFeed>    

//================================================================


//Libraries
import React, { useState, useEffect } from 'react';

//Contexts

//Components

//Functions
import QueryListener from '../../Library/QueryListener';
import ConvertDate from '../../Library/ConvertDate';

//Images
import InProgress from '../Images/Icon_InProgressActivity_Yellow.svg';
import Completed from '../Images/Icon_CompletedActivity_Green.svg';
import Rejected from '../Images/Icon_RejectedActivity_Red.svg';

//CSS
import './ActivityFeed.css';


export default function ActivityFeed({
  task
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------
  
    // Used to determine the component status > 'onload', 'error'
    const [componentStatus, setComponentStatus] = useState('onload');
    
    // Used to store all the tasks and their activity feeds
    const [taskActivities, setTaskActivities] = useState([]);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Query Listener
    // Get all activity feeds from all related tasks
    useEffect(() => {
      
      if (task === undefined) return;
      if (task?.requestid === undefined) return;
      
      function onLoadChange(document){
        
        // Reverse the order of the activity feed
        document.forEach(task => {

          // Check if the task has a 'activityfeed' property that is an array
          if (Array.isArray(task.activityfeed)) {

            // Reverse the nested array
            task.activityfeed.reverse();
            
          }
        });

        setTaskActivities(document);

      }
      
      function onError(error){
        console.log(error);
        setComponentStatus('error');
      }
      
      const unsubscribe = QueryListener('tasks', [
        ['requestid', '==', task?.requestid],
        ['activityfeedvisible', '==', true],
      ], onLoadChange, onLoadChange, onError, ['createddate', 'desc']);
      
      return () =>{
        unsubscribe();
      };
      

    }, [task])
  

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Onload
    //------------------------------------------------------

    if (componentStatus === 'onload') {
      return (
        <div className='p-4'>
          {
            taskActivities.length > 0 && 
            taskActivities.map((task, index) => (

              <div key={index} className='flex flex-col gap-2 pb-4'>

                  {/* ----- TASK TYPE LABEL ----- */}
                  {
                    // In Progress (Yellow Label)
                    task?.status === 'In Progress' || task?.status === 'Not Started' ?
                    <div className='flex flex-row gap-2 w-fit rounded-md z-10 font-medium capitalize px-2 py-1 bg-[#FBF6D9]'>
                      <img src={InProgress} alt='In Progress'></img>
                      <label>{task?.tasktype}</label>
                    </div>
                    
                    // Completed (Green Label)
                    : task?.status === 'Completed' ?
                    <div className='flex flex-row gap-2 w-fit rounded-md z-10 font-medium capitalize px-2 py-1 bg-[#E7F2DA]'>
                      <img src={Completed} alt='In Progress'></img>
                      <label>{task?.tasktype}</label>
                    </div>
                    : 

                    // Rejected (Red Label)
                    <div className='flex flex-row gap-2 w-fit rounded-md z-10 font-medium capitalize px-2 py-1 bg-[#F3CECE]'>
                      <img src={Rejected} alt='In Progress'></img>
                      <label>{task?.tasktype}</label>
                    </div>
                  }

                  {
                    task.activityfeed?.length > 0 &&
                    task.activityfeed.map((activity, index) => (
                      <div key={index} className='relative pl-8 group'>

                        {/* <!-- Vertical line (::before) ~ Date ~ Title ~ Circle marker (::after) --> */}
                        <div className='flex flex-col items-start mb-1 group-last:before:hidden before:absolute before:left-2 before:h-full before:px-px before:bg-slate-300 before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 after:w-2 after:h-2 after:bg-[#00A7A4] after:border-4 after:box-content after:border-slate-50 after:rounded-full after:-translate-x-1/2 after:translate-y-1.5'>
                            <div className='font-light'>{ConvertDate(activity?.activitydate)}</div>
                        </div>
    
                        {/* <!-- Content --> */}
                        <div className='font-medium text-[#00A7A4]'>
                          <b className='font-medium text-black capitalize'>{activity?.actionedby?.givenname} {activity?.actionedby?.surname}</b> {activity?.action}
                        </div>
                    </div>
                    ))
                  }

            </div>
            ))
          }
        </div>
      )  
    }

    //------------------------------------------------------
    //  Error
    //------------------------------------------------------

    else {
      return (
        <div>
          <h4 className='font-medium'>Oops! Something went wrong.</h4>

          Failed to load activity feed.
        </div>
      )
    }

}
