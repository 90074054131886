// Libraries
import { getStorage, ref, deleteObject} from 'firebase/storage';

/**
 * Generic function to delete a file in a GCS bucket
 * 
 * @param {string} path
 * @param {object} file
 * 
 * ```
 * const path = '/templates/email_template.html';

 * DeleteFile(path).then(() =>{
 * 
 *   console.log('url', url);
 * 
 * });
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/storage/web/delete-files
 */

export default async function DeleteFile(path){

  //------------------------------------------------------
  //  Delete File
  //------------------------------------------------------

    try {

        // Create a root reference
        const storage = getStorage();
        
        // Create a reference to 'file' we want to upload
        // 'images/figma.png'
        const storageRef = ref(storage, path);

        // Delete the file
        return deleteObject(storageRef)

    } catch (error) {

        throw new Error(`Function DeleteFile failed to complete, error ${error}`);

    }

  //------------------------------------------------------
}
