//================================================================
//  Component: User Search
//================================================================

//  Purpose: This is an input field that allows you to search all LL Users and select a user

//  Properties:
//    - selectedUser = {A useState, this stores the current selected user, search results and query message}
//    - setSelectedUser = {A useState, this is used to set the selected user state}

//  * REQUIRED *
//  You must have a useReducer in the parent component, with the following properties:
//    - newUserInput = {string, this stores the current selected user email}
//    - newUserGivenName = {string, this stores the current selected user given name}
//    - newUserSurname = {string, this stores the current selected user surname}

//  Example:
//    <UserSearch
//      selectedUser={formData}
//      setSelectedUser={setFormData}
//    ></UserSearch>    

//================================================================


//Libraries
import React, { useReducer } from 'react';

//Contexts

//Components

//Functions
import QueryDocument from '../../Library/QueryDocument';

//Images

export default function UserSearch({
    selectedUser,
    setSelectedUser
}) {

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store the search data
    const [searchData, setSearchData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            'searchResults': '',
            'queryMessage': '',
        }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Function used to handle search
    function handleSearch(value) {

        setSearchData({ 'searchResults': '' });
        setSelectedUser({ 'newUserInput': value });

        // Validate input
        if (selectedUser?.newUserInput?.length === 0) {
            return setSearchData({ 'queryMessage': 'Please enter an email address.' });

        }

        if (selectedUser?.newUserInput?.length === 2) {
            return setSearchData({ 'queryMessage': 'Search requires more than two characters.' });

        }

        const query = selectedUser?.newUserInput?.toLowerCase();

        // Search by characters in email
        return QueryDocument('users', [
            ['searcharray', 'array-contains-any', [query]], 
            ['usertype', '==', 'lendleaseuser']
        ])
        .then((users) => {

            setSearchData({
                'searchResults': users,
                'queryMessage': `Showing ${users?.length} result(s)`
            });

        })
        .catch((error) => {

            console.log(error);
            setSearchData({ 'queryMessage': 'Oops something went wrong!' });

        });

    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
        <div className='flex flex-col w-full h-full relative'>

            {/* ------------------------------------------------------ */}
            {/*  Users Search Bar                                      */}
            {/* ------------------------------------------------------ */}

            {/* Search Bar */}
            <div className='flex flex-row items-center'>

                {/* Search Input Field */}
                <input
                    className={
                        searchData?.searchResults?.length > 0 ?
                        'w-full h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#D8D8D8] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none'
                        : 
                        'w-full h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#D8D8D8] rounded-[5px]'
                    }
                    type='text'
                    placeholder='Search user by email address'
                    value={selectedUser?.newUserInput}
                    onChange={(evt) => handleSearch(evt.target.value)}
                    onPaste={(evt) => handleSearch(evt.clipboardData.getData('Text'))}
                ></input>

            </div>

            {/* Search Results */}
            {
            searchData?.searchResults?.length > 0 &&
            (
                <div className='absolute w-full top-11 max-h-[150px] overflow-y-auto bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'>
                    
                    {/* Results */}
                    {
                        searchData?.searchResults.map((user, index) => (
                        <div 
                            key={index} 
                            className='flex flex-row justify-between px-4 py-[13px] cursor-pointer rounded-sm hover:bg-[#F0F0F0]' 
                            onClick={() => {
                                setSearchData({ 'searchResults': '' })
                                setSelectedUser({ 
                                    'newUserInput': user?.emailaddress,
                                    'newUserGivenName': user?.givenname,
                                    'newUserSurname': user?.surname
                                });
                            }}
                        >
            
                            {/* User Detail */}
                            <div className='flex flex-col'>
                                <p className='m-0 font-medium text-[14px]'>{user?.givenname} {user?.surname}</p>
                                <p className='m-0 text-[14px]'>
                                    {user?.emailaddress}
                                    <br></br>
                                    ({user?.jobtitle})
                                </p>
                            </div>
                        
                        </div>
                        ))
                    }
                    
                    {/* Total */}
                    <div className='text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={searchData?.queryMessage.length === 0}>
                        {searchData?.queryMessage}
                    </div>

                </div>
            )
            }

            
        </div>
    )

  //------------------------------------------------------
}
