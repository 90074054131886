//================================================================
//  Component: Execute Tab
//================================================================

//  Purpose: This contains the execute information for a commercial task

//  Properties:
//    - task = {An object, contains the commercial task details}

//  Example:
//    <ExecuteTab
//      task={task}
//    ></ExecuteTab>    

// ==============================================================

// Libraries
import React, { useContext, useReducer, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

// Contexts
import { GetUser, SetToast } from '../../../../Library/GlobalContexts';

// Components
import Tooltip from '../../../../Components/Tooltip/Tooltip';
import AddContractPane from './Panes/AddContractPane';
import ViewContractPane from './Panes/ViewContractPane';
import AgreementInformation from './AgreementInformation';
import DeleteContractModal from './Modals/DeleteContractModal';
import CompleteRequestModal from './Modals/CompleteRequestModal';

//Functions
import WriteDocument from '../../../../Library/WriteDocument';
import QueryListener from '../../../../Library/QueryListener';
import ConvertDate from '../../../../Library/ConvertDate';

// Images
import Expand from '../../../../Components/Images/Icon_Collapse_Teal.svg';
import Collapse from '../../../../Components/Images/Icon_Expand_Teal.svg';
import Add from '../../../../Components/Images/Icon_Add_Teal.svg';
import AddDisabled from '../../../../Components/Images/Icon_Add_Grey.svg';
import DeleteDisabled from '../../../../Components/Images/Icon_DeleteDisabled_Grey.svg';

// CSS
import '../../Task.css'

export default function ExecuteTab({
    task
}) {

    //------------------------------------------------------
    // Constants
    //------------------------------------------------------

    const isExecutionNotRequired = task?.executionnotrequired === true;

    //------------------------------------------------------
    //  useContext & React Router
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setToast = useContext(SetToast);
    const navigate = useNavigate();
    const routerUseLocation = useLocation();

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to toggle the visibility of the add contract pane
    const [addContractPaneOpen, setAddContractPaneOpen] = useState(undefined);

    // Used to toggle the visibility of the view contract pane
    const [viewContractPane, setViewContractPane] = useState(undefined);

    // Used to toggle the visibility of the delete contract pane
    const [deleteContractPane, setDeleteContractPane] = useState(undefined);

    // Used to toggle the visibility of the complete request modal
    const [completeRequestModal, setCompleteRequestModal] = useState(undefined);

    // Used to store all pending 'CSM' tasks
    const [pendingTasks, setPendingTasks] = useState([]);

    // Used to store the checkbox state - whether the user chooses to close the ticket despite there being pending tasks
    const [confirmationChecked, setConfirmationChecked] = useState(false);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store whether a section is expanded
    const [taskSection, setTaskSection] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'signedContractsCollapsed': true,
            'agreementInformationCollapsed': true,
            'submissionErrors': ''
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Function used to check if someone can proceed to 'Complete' stage
    function allowStageButton() {

        if (task?.agreement?.vendor?.vendorname?.length === 0) return true;

        if (task?.agreement?.agreementstartdate?.length === 0) return true;

        if (task?.agreement?.agreementenddate?.length === 0) return true;

        if (task?.agreement?.agreementterm?.term?.length === 0) return true;

        if (task?.agreement?.agreementterm?.period?.length === 0) return true;

        if (task?.agreement?.currency?.length === 0) return true;

        if (task?.agreement?.totalagreementamount?.length === 0) return true;

        if (task?.agreement?.autorenewals?.length === 0) return true;
        if (task?.agreement?.autorenewals === 'Yes' && task?.agreement?.noticeperiodforautorenewaltermination?.length === 0) return true;

        if (task?.agreement?.extensionagreement?.length === 0) return true;
        if (task?.agreement?.extensionagreement === 'Yes' && task?.agreement?.noticeperiodforextension?.length === 0) return true;

        if (task?.status === 'Completed') return true;

        return false;

    }

    // Function used to proceed to 'Complete' stage
    function closeTicket() {

        //=================================================
        //  Check for Pending Tasks
        //=================================================

        if (pendingTasks?.length > 0 && confirmationChecked === false) return setCompleteRequestModal(pendingTasks);

        //=================================================
        //  Add Agreement Document in Firestore
        //=================================================

        const docPromises = [];

        // Create a searcharray for lookup fields
        const searchArray = [];
        let previousValue = '';
        [...task?.poanumber]?.forEach((value, index) => {

            // Skip the first character
            if (index === 0) {

                previousValue = value;
                return searchArray.push(value.toLowerCase());

            }

            // Create an array of each character
            searchArray.push(`${previousValue}${value}`.toLowerCase());
            previousValue = `${previousValue}${value}`.toLowerCase();

        });

        const agreementId = `${btoa(task?.agreement?.vendor?.vendorname)}-${Date.now()}`;

        const agreementDoc = {
            'agreementid': agreementId,
            'requestid': [task?.requestid],
            'poanumber': task?.poanumber,
            'searcharray': searchArray,
            'createddate': new Date(),
            'createdby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'lastmodifieddate': new Date(),
            'lastmodifiedby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'shortdescription': task?.shortdescription,
            'status': 'Active',
            'expirydate': '',

            'projectcode': task?.projectcode,
            'tasknumber': task?.tasknumber,
            'expendituretype': task?.expendituretype,
            'commercialscope': task?.commercialscope,
            'sponsorname': task?.sponsorname,
            'buysellagreement': task?.buysellagreement,
            'agreementtype': task?.agreementtype,
            'agreementstartdate': task?.agreement?.agreementstartdate,
            'agreementenddate': task?.agreement?.agreementenddate,
            'executionmethod': task?.agreement?.executionmethod,
            'executiondate': task?.agreement?.executiondate,
            'lendleaseentity': task?.agreement?.lendleaseentity,
            'vendor': {
                'vendorname': task?.agreement?.vendor?.vendorname,
                'vendorid': task?.agreement?.vendor?.vendorid,
                'tradingas': task?.agreement?.vendor?.tradingas,
            },
            'currency': task?.agreement?.currency,
            'estannualcontractvalue': task?.estannualcontractvalue,
            'esttotalcontractvalue': task?.esttotalcontractvalue,
            'agreementterm': {
                'term': task?.agreement?.agreementterm?.term,
                'period': task?.agreement?.agreementterm?.period
            },
            'totalagreementamount': task?.agreement?.totalagreementamount,
            'autorenewals': task?.agreement?.autorenewals,
            'noticeperiodforautorenewaltermination': task?.agreement?.noticeperiodforautorenewaltermination,
            'extensionagreement': task?.agreement?.extensionagreement,
            'noticeperiodforextension': task?.agreement?.noticeperiodforextension,
            'signedcontracts': task?.signedcontracts,
        }

        docPromises.push(
            WriteDocument('agreements', agreementId, agreementDoc, true)
        );

        //=================================================
        //  Update Task Document in Firestore
        //=================================================

        const updatedDoc = {
            'stage': 'Completed',
            'status': 'Completed',
            'lastmodifiedby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'lastmodifieddate': new Date(),
            'agreement': {
                'agreementid': agreementId
            },
            'activityfeed': [...task?.activityfeed,
            {
                'activitydate': new Date(),
                'actionedby': {
                    'email': getUser?.emailaddress,
                    'givenname': getUser?.givenname,
                    'surname': getUser?.surname
                },
                'action': 'completed',
                'comments': ''
            }
            ]
        };

        docPromises.push(
            WriteDocument('tasks', task?.taskid, updatedDoc, true)
        );

        Promise.all(docPromises)
            .then(() => {

                // Navigate to Triage Tab
                navigate(`/${routerUseLocation.pathname.split('/')[1]}/${task?.taskid}?view=triage`);

            })
            .catch((error) => {

                console.log('Error', error);
                setTaskSection('Failed to close task, refresh and try again!');

            });

    }


    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // Query Listener
    // - Get all pending tasks
    useEffect(() => {

        if (task?.requestid === undefined) return;

        function onLoadChange(document) {

            // Filter by status
            if (document.length > 0) {
                document = document.filter((task) => task.status === 'Not Started' || task.status === 'In Progress');
            }

            setPendingTasks(document);
        }

        function onError(error) {

            console.log(error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to get pending tasks related to this request.',
            });
        }

        const unsubscribe = QueryListener('tasks', [
            ['requestid', '==', task?.requestid],
            ['tasktype', 'not-in', ['Business Request', 'Commercial Review']],
        ], onLoadChange, onLoadChange, onError);


        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, [task])

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-col gap-2 p-0'>

            {/* ===================================================== */}
            {/*  Execute Tab Bypass Status                            */}
            {/* ===================================================== */}

            {
                isExecutionNotRequired && <div className='border-l-[5px] border-gray-500 bg-gray-100 p-3 shadow-sm mb-4'>
                    <p className='text-base font-semibold mb-1'>Execution not required</p>
                    <p className='text-sm mb-0'>
                        <span className='font-semibold'>{task?.lastmodifiedby?.givenname} {task?.lastmodifiedby?.surname}</span> has bypassed the execution of this business request on
                        <span> {ConvertDate(task?.lastmodifieddate)}</span>
                    </p>
                </div>
            }

            {/* ===================================================== */}
            {/*  Signed Contracts                                     */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'signedContractsCollapsed': !taskSection.signedContractsCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.signedContractsCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Signed Contracts</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.signedContractsCollapsed}></hr>

                {/* ------------- SIGNED CONTRACTS ------------- */}

                <div className='p-[3%]' hidden={taskSection?.signedContractsCollapsed}>

                    {/*  Contracts Table */}
                    <div className='border border-[#D8D8D8] rounded px-[1%]'>
                        <table className='w-full max-h-96 overflow-y-scroll'>
                            <thead>
                                <tr className='text-[#424242]'>
                                    <th className='py-[15px] px-[5px] font-medium'></th>
                                    <th className='py-[15px] px-[10px] font-medium'>ID</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Signees</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Short Description</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Created By</th>
                                    <th className='py-[15px] px-[15px] font-medium min-w-20'>
                                        <Tooltip
                                            message={'+ Add Contract'}
                                            children={
                                                <img className='cursor-pointer' src={task?.status === 'Completed' || isExecutionNotRequired ? AddDisabled : Add} alt='add' onClick={() => task?.status === 'Completed' || isExecutionNotRequired ? null : setAddContractPaneOpen(task)}></img>
                                            }
                                        ></Tooltip>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    task?.signedcontracts?.length === 0 || task?.signedcontracts === undefined ?
                                        (
                                            <tr>
                                                <td className='border-t border-t-[#D8D8D8]' colSpan={6}>
                                                    <div className='text-center p-[2%]'>
                                                        No contracts found.
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            task?.signedcontracts?.map((contract, index) => (
                                                <tr key={index} className='hover:bg-[#F0F7F7]'>

                                                    <td className='py-[10px] px-[5px] border-t border-t-[#D8D8D8]'></td>

                                                    {/* ID */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8] break-all uppercase cursor-pointer hover:underline hover:font-medium' onClick={() => setViewContractPane(contract)}>
                                                        {contract?.contractid}
                                                    </td>

                                                    {/* Signees */}
                                                    <td className='flex flex-col py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        <ul className='p-0 m-0'>
                                                            {
                                                                contract?.signees.map((signee, index) => (
                                                                    <li key={index} className='list-disc'>
                                                                        <p className='m-0'>{signee?.signeeemail}</p>
                                                                        <p className='text-[13px] m-0 text-slate-600'>{signee?.signeename}</p>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </td>

                                                    {/* Short Description */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        <div className='max-w-[250px] leading-normal whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer'>
                                                            {contract?.shortdescription?.length > 0 ? contract?.shortdescription : '-'}
                                                        </div>
                                                    </td>

                                                    {/* Created By */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8] capitalize'>
                                                        {contract?.createdby?.givenname} {contract?.createdby?.surname}
                                                    </td>

                                                    {/* Cancel */}
                                                    <td className='py-[10px] px-[15px] border-t border-t-[#D8D8D8] cursor-pointer align-middle min-w-20'>
                                                        {
                                                            (task?.status === 'Completed' || isExecutionNotRequired) ?
                                                                <img className='cursor-not-allowed' src={DeleteDisabled} alt='cancel'></img>
                                                                :
                                                                <Tooltip
                                                                    message={'Delete Contract'}
                                                                    children={
                                                                        <div className='Cancel-Icon mt-2 p-0' onClick={() => setDeleteContractPane(contract)}></div>
                                                                    }
                                                                ></Tooltip>
                                                        }
                                                    </td>

                                                </tr>
                                            ))
                                        )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Agreement Information                                */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'agreementInformationCollapsed': !taskSection.agreementInformationCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.agreementInformationCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Agreement Information</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.agreementInformationCollapsed}></hr>

                {/* ------------- AGREEMENT INFORMATION ------------- */}
                <div className='px-[3%] py-[2%]' hidden={taskSection?.agreementInformationCollapsed}>

                    <AgreementInformation
                        collectionid='tasks'
                        documentid={task?.taskid}
                        task={task}
                    ></AgreementInformation>

                    {/* Complete Button */}
                    <div className='flex flex-col gap-2 pt-3'>

                        <button
                            className='Primary-Button w-fit'
                            onClick={() => closeTicket()}
                            disabled={allowStageButton()}
                        >
                            Complete
                        </button>

                        <label className='text-[14px] text-slate-600 mb-0'>
                            Clicking this button will close this request and update the status to <b className='font-medium'>Completed</b>.
                        </label>

                        <label className='pt-[5px] text-[#DE0000] font-semibold'>{taskSection.submissionErrors}</label>

                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Add Contract Pane                                    */}
            {/* ===================================================== */}

            <AddContractPane
                addContractPaneOpen={addContractPaneOpen}
                setAddContractPaneOpen={setAddContractPaneOpen}
            ></AddContractPane>


            {/* ===================================================== */}
            {/*  View Contract Pane                                   */}
            {/* ===================================================== */}

            <ViewContractPane
                viewContractPane={viewContractPane}
                setViewContractPane={setViewContractPane}
            ></ViewContractPane>


            {/* ===================================================== */}
            {/*  Delete Contract Modal                                */}
            {/* ===================================================== */}

            <DeleteContractModal
                task={task}
                deleteContractPane={deleteContractPane}
                setDeleteContractPane={setDeleteContractPane}
            ></DeleteContractModal>

            {/* ===================================================== */}
            {/*  Complete Request Modal                               */}
            {/* ===================================================== */}

            <CompleteRequestModal
                completeRequestModal={completeRequestModal}
                setCompleteRequestModal={setCompleteRequestModal}
                confirmationChecked={confirmationChecked}
                setConfirmationChecked={setConfirmationChecked}
                closeTicket={closeTicket}
            ></CompleteRequestModal>

        </div>
    )
}
