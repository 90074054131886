//================================================================
//  Page: My Tasks
//================================================================

//  Purpose: This page shows all commercial tasks assigned to the current user

//  Example:
//    <MyTasks></MyTasks>    

//================================================================


// Libraries
import React, { useReducer } from 'react';

// Contexts

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';
import ReviewAndNegotiate from './Tabs/ReviewAndNegotiate';
import Execute from './Tabs/Execute';
import Completed from './Tabs/Completed';
import Disengagement from './Tabs/Disengagement';
import All from './Tabs/All';

// Tabs

// Functions

// Images

// CSS
import './MyTasks.css';

 
export default function MyTasks() {

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store the total requests per stage/view
    const [requests, setRequests] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'reviewAndNegotiateRequests': [],
        'reviewAndNegotiateRequestsFiltered': [],
        'executeRequests': [],
        'executeRequestsFiltered': [],
        'disengagementRequests': [],
        'disengagementRequestsFiltered': [],
      }
    );

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='Page-Header-Container'>
            <p className='font-medium text-[20px] mb-0'> My Tasks </p>
          </div>
        }
        body={
          <div>

            <TabView
              defaultView={1}

              //------------------------------------------------------
              // Review and Negotiate
              //------------------------------------------------------

              oneTitle={
                <div className='flex flex-row gap-2'>
                  Review and Negotiate
                  <div className='flex items-center justify-center h-[22px] w-[22px] rounded-full bg-[#006866] text-white text-[12px] font-bold' hidden={requests?.reviewAndNegotiateRequests?.length === 0}>
                    {requests?.reviewAndNegotiateRequests?.length}
                  </div>
                </div>
              }
              oneRoute = {'/mytasks?view=reviewandnegotiate'}
              oneContent={
                <ReviewAndNegotiate
                  requests={requests}
                  setRequests={setRequests}
                ></ReviewAndNegotiate>
              }

              //------------------------------------------------------
              // Execute
              //------------------------------------------------------

              twoTitle={
                <div className='flex flex-row gap-2'>
                  Execute
                  <div className='flex items-center justify-center h-[22px] w-[22px] rounded-full bg-[#006866] text-white text-[12px] font-bold' hidden={requests?.executeRequests?.length === 0}>
                    {requests?.executeRequests?.length}
                  </div>
                </div>
              }
              twoRoute = {'/mytasks?view=execute'}
              twoContent={
                <Execute
                  requests={requests}
                  setRequests={setRequests}
                ></Execute>
              }

              //------------------------------------------------------
              // Completed
              //------------------------------------------------------

              threeTitle={'Completed'}
              threeRoute = {'/mytasks?view=completed'}
              threeContent={
                <Completed></Completed>
              }

              //------------------------------------------------------
              // Disengagement
              //------------------------------------------------------

              fourTitle={
                <div className='flex flex-row gap-2'>
                  Disengagement
                  <div className='flex items-center justify-center h-[22px] w-[22px] rounded-full bg-[#006866] text-white text-[12px] font-bold' hidden={requests?.disengagementRequests?.length === 0}>
                    {requests?.disengagementRequests?.length}
                  </div>
                </div>
              }
              fourRoute = {'/mytasks?view=disengagement'}
              fourContent={
                <Disengagement
                  requests={requests}
                  setRequests={setRequests}
                ></Disengagement>
              }


              //------------------------------------------------------
              // All
              //------------------------------------------------------

              fiveTitle={'All'}
              fiveRoute = {'/mytasks?view=all'}
              fiveContent={
                <All></All>
              }

            ></TabView>    

          </div>
        }
      ></PageComponent> 
    )
}
