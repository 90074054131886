//================================================================
//  Component: Agreement Information
//================================================================

//  Purpose: This contains the 'Agreement Information' section on the Execute stage of a ticket

//  Properties:
//    - collectionid = {string, Firestore collection id}
//    - documentid = {string, Firestore document id}

//  Example:
//    <AgreementInformation
//      collectionid={collectionid}
//      documentid={documentid}
//    ></AgreementInformation>    

// ==============================================================

// Libraries
import React from 'react'

// Components
import LiveField from '../../../../Components/LiveField/LiveField';

// Images
import Info from '../../../../Components/Images/Icon_Info_Blue.svg';

// CSS
import '../../Task.css';


export default function AgreementInformation({
    collectionid,
    documentid,
    task,
}) {

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <>
        
            {/* 1. Vendor Name  */}
            <LiveField
                type={'lookup'}
                collectionid={'tasks'}
                documentid={documentid}
                fieldpath={'agreement.vendor'}
                label={<label className='font-medium'> 1. Vendor Name <span className='text-[#C4314B]'>*</span></label>}
                defaultValue={task?.agreement?.vendor?.vendorname}
                placeholder={'Search vendor by name'}
                dataFieldCollectionid={'vendors'}
                dataFieldPrimary={'vendorname'}
                dataFieldSecondary={'tradingas'}
                dataFieldTertiary={'vendorid'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
            ></LiveField> 

            <div className='w-[450px] flex flex-row gap-2 px-[15px] py-[7px] rounded-md mx-1 my-1 bg-[#E9F7FF] border-1 border-[#0972D3]'>
                <img src={Info} alt='info'></img>
                <p className='mb-0'>To add a new vendor, click <a href={'https://inventory.store.lendlease.com/inventory?view=vendors'} target='_blank' rel='noopener noreferrer'>here</a>.</p>
            </div>
            
            {/* 2. What is the start date of this agreement? */}
            <LiveField
                type={'date-string'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'agreement.agreementstartdate'}
                defaultValue={task?.agreement?.agreementstartdate}
                label={<label className='font-medium'> 2. What is the start date of this agreement? <span className='text-[#C4314B]'>*</span></label>}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
            ></LiveField>   

            {/* 3. What is the end date of this agreement? */}
            <LiveField
                type={'date-string'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'agreement.agreementenddate'}
                defaultValue={task?.agreement?.agreementenddate}
                label={<label className='font-medium'> 3. What is the end date of this agreement? <span className='text-[#C4314B]'>*</span></label>}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
            ></LiveField>   

            {/* 4. What is the term of this agreement? */}
            <div className='gap-[5px] p-[5px]'>

                <label className='font-medium pt-[5px]'>4. What is the term of this agreement? <span className='text-[#C4314B]'>*</span></label>
                <div className='flex flex-row pt-[10px]'>

                    <LiveField
                        type={'number'}
                        collectionid={collectionid}
                        documentid={documentid}
                        fieldpath={'agreement.agreementterm.term'}
                        defaultValue={task?.agreement?.agreementterm?.term}
                        disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
                        styleInput={{width: '150px'}}
                    ></LiveField>

                    <LiveField
                        type={'choice'}
                        collectionid={collectionid}
                        documentid={documentid}
                        fieldpath={'agreement.agreementterm.period'}
                        defaultValue={task?.agreement?.agreementterm?.period}
                        disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
                        options={[
                            'Days',
                            'Months',
                            'Years',
                        ]}
                        styleInput={{width: '120px'}}
                    ></LiveField>   

                </div>
            </div>

            {/* 5. What is the currency? */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'agreement.currency'}
                defaultValue={task?.agreement?.currency}
                label={<label className='font-medium'> 5. What is the currency? <span className='text-[#C4314B]'>*</span></label>}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
                options={[
                    'AUD',
                    'GBP',
                    'SGD',
                    'USD',
                ]}
            ></LiveField> 

            {/* 6. Total agreement amount (excl. tax)? */}
            <LiveField
                type={'number'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'agreement.totalagreementamount'}
                defaultValue={task?.agreement?.totalagreementamount}
                label={<label className='font-medium'> 6. Total agreement amount (excl. tax)? <span className='text-[#C4314B]'>*</span></label>}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
            ></LiveField> 

            {/* 7. Auto renewals?  */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'agreement.autorenewals'}
                defaultValue={task?.agreement?.autorenewals}
                label={<label className='font-medium'> 7. Auto renewals? <span className='text-[#C4314B]'>*</span></label>}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
                options={[
                    'Yes',
                    'No',
                ]}
            ></LiveField>

            {/* 7a. Notice period to terminate auto renewal? */}
            {
                task?.agreement?.autorenewals === 'Yes' &&
                    <LiveField
                        type={'date-string'}
                        collectionid={collectionid}
                        documentid={documentid}
                        fieldpath={'agreement.noticeperiodforautorenewaltermination'}
                        defaultValue={task?.agreement?.noticeperiodforautorenewaltermination}
                        label={<label className='font-medium'> 7a. Notice period to terminate auto renewal? <span className='text-[#C4314B]'>*</span></label>}
                        disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
                    ></LiveField>
            }

            {/* 8. Extension agreement? */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'agreement.extensionagreement'}
                defaultValue={task?.agreement?.extensionagreement}
                label={<label className='font-medium'> 8. Extension agreement? <span className='text-[#C4314B]'>*</span></label>}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
                options={[
                    'Yes',
                    'No',
                ]}
            ></LiveField>

            {/* 8a. Notice period to extend agreement? */}
            {

                task?.agreement?.extensionagreement === 'Yes' &&
                    <LiveField
                        type={'date-string'}
                        collectionid={collectionid}
                        documentid={documentid}
                        fieldpath={'agreement.noticeperiodforextension'}
                        defaultValue={task?.agreement?.noticeperiodforextension}
                        label={<label className='font-medium'> 8a. Notice period to extend agreement? <span className='text-[#C4314B]'>*</span></label>}
                        disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
                    ></LiveField>
            }

            {/* 11. Lendlease Entity */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'agreement.lendleaseentity'}
                defaultValue={task?.agreement?.lendleaseentity}
                label={<label className='font-medium'> 11. Lendlease Entity <span className='text-[#C4314B]'>*</span></label>}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
                options={[
                    'LLMS',
                    'LLC',
                    'LLiS'
                ]}
            ></LiveField>

            {/* 10. Execution Method */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'agreement.executionmethod'}
                defaultValue={task?.agreement?.executionmethod}
                label={<label className='font-medium'> 10. Execution Method <span className='text-[#C4314B]'>*</span></label>}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') || task?.executionnotrequired === true ? true : false}
                options={[
                    'PoA',
                    's127',
                ]}
            ></LiveField>

            {/* 11. Execution Date */}
            <div hidden={task?.signedcontracts.length === 0}>
                <LiveField
                    type={'date-string'}
                    collectionid={collectionid}
                    documentid={documentid}
                    fieldpath={'agreement.executiondate'}
                    defaultValue={task?.agreement?.executiondate} // Set this to the latest signature 
                    label={<label className='font-medium'> 11. Execution Date <span className='text-[#C4314B]'>*</span></label>}
                    disabled={true}
                ></LiveField>
            </div>

        </>
    )

}
