//================================================================
//  Component: Triage Tab
//================================================================

//  Purpose: This contains the triage information for a commercial task

//  Properties:
//    - task = {An object, contains the commercial task details}
//    - commercialUsers = {An array (useState) - this is the list of all commercial users}
//    - setPageStatus = {A useState, used to determine the status of the page}

//  Example:
//    <TriageTab
//      task={task}
//      commercialUsers={commercialUsers}
//      setPageStatus={setPageStatus}
//    ></TriageTab>    

// ==============================================================

// Libraries
import React from 'react'

// Contexts

// Components
import AssignTask from '../../../../Components/AssignTask/AssignTask';

//Functions
import ConvertDate from '../../../../Library/ConvertDate';

// CSS
import '../../Task.css'

export default function TriageTab({
    task
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

        return (
            <div className='flex flex-col gap-2 p-0'>

                {/* =========================================================== */}
                {/*  Status Label                                               */}
                {/* =========================================================== */}

                {
                    [
                        { 
                            'name': 'Completed', 
                            'description': <><b className='font-medium'>{task?.lastmodifiedby?.givenname} {task?.lastmodifiedby?.surname}</b> has completed this business request on {ConvertDate(task?.lastmodifieddate)}. </>, 
                            'background': '#E7F2DA', 
                            'status': 'Completed', 
                            'color': '#8ABB45'
                        },
                        { 
                            'name': 'Cancelled', 
                            'description': <><b className='font-medium'>{task?.lastmodifiedby?.givenname} {task?.lastmodifiedby?.surname}</b> has completed this business request on {ConvertDate(task?.lastmodifieddate)}. </>, 
                            'background': '#EBEBEB', 
                            'status': 'Cancelled', 
                            'color': '#616161'
                        },
                        { 
                            'name': 'Rejected', 
                            'description': 
                                <>
                                    <b className='font-medium'>{task?.lastmodifiedby?.givenname} {task?.lastmodifiedby?.surname}</b> has rejected this business request on {ConvertDate(task?.lastmodifieddate)}.
                                    <br></br>
                                    Reason: {task?.activityfeed?.filter((item) => item.action === 'rejected')?.comments?.length > 0 ? task?.activityfeed?.filter((item) => item.action === 'rejected')?.comments : 'N/A'}
                                </>, 
                            'background': '#FCF4F6', 
                            'status': 'Rejected', 
                            'color': '#C4314B'
                        },
                    ]
                    ?.filter((style) => style.status === task?.status)
                    .map((object, index) => (

                    <div className='px-4 py-3 mb-2' key={index} style={{ backgroundColor: object.background, borderLeft: `5px solid ${object.color}` }}>
                        <h6 className='text-black'>{object.name?.toUpperCase()}</h6>
                        <p className='mb-0'>{object.description}</p>
                    </div>

                    ))
                }

                {/* =========================================================== */}
                {/*  Assigned To                                                */}
                {/* =========================================================== */}

                <div className='Task-Section-Container'>
                    <h4 className='text-[20px] px-[3%] py-[18px] m-0'>Assigned To</h4>
                    <hr className='m-0'></hr>


                    <div className='p-[3%]'>

                        {/*  Assigned To */}
                        <div className='FormComponent-Row'>
                            <label className='font-medium mt-0'> Assignee </label>
                            {
                                task !== undefined &&
                                <AssignTask
                                    query={['roles.commercialUser', '==', true]}
                                    currentRequest={task}
                                    styleInput={{ width: '300px' }}
                                    disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') ? true : false}
                                ></AssignTask>
                            }
                        </div>

                        
                        {/*  Date Assigned */}
                        <div className='FormComponent-Row'>
                            <label className='font-medium'> Date Assigned </label>
                            <input
                                className='Input-Field-Text'
                                style={{width: '300px'}}
                                type='text'
                                value={ConvertDate(task?.assigneddate)}
                                disabled
                            ></input>
                        </div>

                        {/*  Request Type */}
                        <div className='FormComponent-Row'>
                            <label className='font-medium'> Type of Request </label>
                            <input
                                className='Input-Field-Text'
                                type='text'
                                value={task?.requesttype}
                                disabled
                            ></input>
                            
                        </div>

                    </div>

                </div>

            </div>
        )
}
