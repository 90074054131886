  //================================================================
//  Tab: Execute 
//================================================================

//  Purpose: This tab displays all the requests in the 'Execute' stage

//  Properties:
//    - requests = {An array (useState) - this is the list of all the user's commercial requests in the 'execute' stage}
//    - setRequests = {useState, used to set the requests state}
//    - commercialUsers = {An array (useState) - this is the list of all commercial users}

//  Example:
//    <Execute
//      requests={requests}
//      setRequests={setRequests}
//      commercialUsers={commercialUsers}
//    ></Execute>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import ExportToCSV from '../../../Components/ExportToCSV/ExportToCSV';
import AssignTask from '../../../Components/AssignTask/AssignTask';

//Functions
import QueryListener from '../../../Library/QueryListener';
import ConvertDate from '../../../Library/ConvertDate';

//Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';

export default function Execute({
  requests, 
  setRequests
}) {

  //------------------------------------------------------
  //  useContext and React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState();
    
    // Search Input
    const [searchInput, setSearchInput] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Handles search function
    function handleSearch(value) {

      setSearchInput(value);

      // Reset the Filter
      if (value.length === 0) {

        setRequests({'executeRequestsFiltered': requests.executeRequests});

      };

      // Search Filter
      //  1. Application Name
      //  2. Short Description
      //  2. Assigned To
      const searchResults = requests.executeRequests.filter((object) =>
        object?.applicationname?.toLowerCase().includes(value.toLowerCase()) ||
        object?.shortdescription?.toLowerCase().includes(value.toLowerCase()) || 
        object?.assignedto?.toLowerCase().includes(value.toLowerCase())
      );

      // Save the filtered search results to the useState
      setRequests({ 'executeRequestsFiltered': searchResults });

    };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Query Listener
    // - Get all commercial requests in the 'Execute' stage
    useEffect(() => {
          
      function onLoadChange(documents){

        setRequests({ 'executeRequests': documents });
        setRequests({ 'executeRequestsFiltered': documents });

      }
      
      function onError(error){
        console.log(error);
        setPageStatus('error-fatal');
      }
    
      const unsubscribe = QueryListener('tasks', [
        ['assignmentgroup', '==', 'Commercial'],
        ['stage', '==', 'Execute'],
        ['status', '==', 'In Progress'],
        ['assignedto.email', '==', getUser?.emailaddress]
      ], onLoadChange, onLoadChange, onError);
      
      return () =>{
        unsubscribe();
      };
        
    // eslint-disable-next-line
    }, [getUser])
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        body={
          <div className='flex flex-col px-[15px] gap-[20px]'>

            {/* ----------------------------------- */}
            {/*  Export                             */}
            {/* ----------------------------------- */}

            <div className='flex flex-row justify-end items-center gap-3'>

              {/* Download Report */}
              <ExportToCSV
                filename={`Execute-${Date.now()}`}
                data={requests?.executeRequestsFiltered}
              ></ExportToCSV>

            </div>

            {/* ----------------------------------- */}
            {/*  Requests Table                     */}
            {/* ----------------------------------- */}

            <div className='Table-Container'>

              {/* ======== Sorting and Filters ========== */}
              <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                <label className='font-semibold self-center'>
                  Total: {requests?.executeRequestsFiltered.length} of {requests?.executeRequests.length}
                </label>

                {/* Search Bar */}
                <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>
                  
                  <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                  <label htmlFor='searchInput'>
                    <input
                        id='searchInput'
                        className='border-none h-[40px] p-0 m-0 outline-none'
                        type='text'
                        placeholder='Search'
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchInput}
                    ></input>
                  </label>
                </div>

              </div>

              <table className='w-full max-h-96 overflow-y-scroll'>
                <thead>
                  <tr className='border-b-[#D8D8D8] border-solid border-b-2'>
                    <th className='py-[15px] px-[20px] font-medium'>Request ID</th>
                    <th className='py-[15px] px-[20px] font-medium'>Created</th>
                    <th className='py-[15px] px-[20px] font-medium'>PoA Number</th>
                    <th className='py-[15px] px-[20px] font-medium'>Requester</th>
                    <th className='py-[15px] px-[20px] font-medium'>Application Name</th>    
                    <th className='py-[15px] px-[20px] font-medium'>Short Description</th>
                    <th className='py-[15px] px-[20px] font-medium'>Stage</th>
                    <th className='py-[15px] px-[20px] font-medium'>Assigned To</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    // No requests found
                    requests?.executeRequestsFiltered?.length === 0 ? 
                    (
                      <tr>
                        <td colSpan={7}>
                          <div className='text-center p-[2%]'>
                            No requests found.
                          </div>
                        </td>
                      </tr>
                    )
                    : 
                    (
                      // Map in each commercial request
                      requests?.executeRequestsFiltered?.map((request, index) => (
                        <tr key={index} className='border-b-[#C8C8C9] border-solid border-b hover:bg-[#F0F7F7]'>

                          {/* Request ID */}
                          <td className='py-[15px] px-[20px] uppercase font-medium cursor-pointer hover:underline' onClick={() => navigate(`/mytasks/${request?.taskid}?view=execute`)}>
                            {request?.requestid}
                          </td>

                          {/* Created Date */}
                          <td className='py-[15px] px-[20px]'>
                            {ConvertDate(request?.createddate).split(',')[0]}
                            <br></br>
                            {ConvertDate(request?.createddate).split(',')[1]}
                          </td>

                          {/* PoA Number */}
                          <td className='py-[15px] px-[20px] uppercase'>
                            {request?.poanumber}
                          </td>

                          {/* Requester Name */}
                          <td className='py-[15px] px-[20px] capitalize'>
                            {request?.createdby?.givenname} {request?.createdby?.surname}
                          </td>

                          {/* Application Name */}
                          <td className='py-[15px] px-[20px]'>{request?.applicationname}</td>

                          {/* Short Description */}
                          <td className='py-[15px] px-[20px]'>
                            <div className='max-w-[250px] leading-normal whitespace-nowrap overflow-hidden text-ellipsis'>
                              {request?.shortdescription}
                            </div>
                          </td>

                          {/* Stage */}
                          <td className='pt-[10px] pb-[5px] px-[20px]'>
                            <div>
                              {
                                [
                                  {'name': 'Triage', 'background': '#00A7A4', 'color': 'white', 'bordercolor': '#00A7A4', 'stage': 'Triage'},
                                  {'name': 'Review and Negotiate', 'background': '#DC9A03', 'color': 'white', 'bordercolor': '#DC9A03', 'stage': 'Review and Negotiate'}, 
                                  {'name': 'Execute', 'background': '#006866', 'color': 'white', 'bordercolor': '#006866', 'stage': 'Execute'},
                                  {'name': 'PO', 'background': '#6BB700', 'color': 'white', 'bordercolor': '#6BB700', 'stage': 'PO'}, 
                                  {'name': 'Completed', 'background': '#237B4B', 'color': 'white', 'bordercolor': '#237B4B', 'stage': 'Completed'}, 
                                  {'name': 'Disengagement', 'background': '#C4314B', 'color': 'white', 'bordercolor': '#C4314B', 'stage': 'Disengagement'}, 

                                ].filter((style) => style?.stage === request?.stage).map((object, index) => (
                                  <label key={index} className='Status-Label' style={{backgroundColor: `${object.background}`, color: `${object.color}`, border: `1px solid ${object.bordercolor}`}} >
                                    {object.name}
                                  </label>
                                ))
                              }
                            </div>
                          </td>

                          {/* Assigned To */}
                          <td className='py-[15px] px-[20px]'>
                            <AssignTask
                              query={['roles.commercialUser', '==', true]}
                              currentRequest={request}
                            ></AssignTask>
                          </td>

                        </tr>
                      ))
                    )
                  }
                </tbody>
              </table>

            </div>

          </div>
        }
      ></PageComponent>
    )
}