//================================================================
//  Component: Edit PO Line Item Pane
//================================================================

//  Purpose: This pane will allow users to edit a PO line item to a task

//  Properties:
//    - task = {An object, contains the commercial task details}
//    - editPOLineItemPaneOpen = {useState, used to determine if the pane is visible}
//    - setEditPOLineItemPaneOpen = {useState, used to toggle the visibility of the edit po line pane}
//    - polineitemIndex = {The index of the polineitem in the task.polineitems array}

//  Example:
//    <EditPOLineItemPane
//      task={task}
//      editPOLineItemPaneOpen={editPOLineItemPaneOpen}
//      setEditPOLineItemPaneOpen={setEditPOLineItemPaneOpen}
//      polineitemIndex={polineitemIndex}
//    ></EditPOLineItemPane>    

//================================================================


//Libraries
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

//Contexts

//Components

//Functions
import WriteDocument from '../../../../Library/WriteDocument';

//Images
import Error from '../../../../Components/Images/Icon_ErrorFatal_Red.svg';
import Loading from '../../../../Components/Images/Image_Loading_Ripple.svg';

export default function EditPOLineItemPane({
  task,
  editPOLineItemPaneOpen,
  setEditPOLineItemPaneOpen,
  polineitemIndex,
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to set pane status > 'onload', 'pending', 'error'
  const [paneStatus, setPaneStatus] = useState('onload');

  // --------------------------------------------------
  //  Form State
  // --------------------------------------------------

  const { register, handleSubmit, setValue, formState: { errors }, reset, clearErrors } = useForm();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Submit Handler
  function onSubmit(data) {


    // ----------------------------------------------
    //  Submit Request
    // ----------------------------------------------

    const updatedDocument = {
      'projectcode': data.projectcode,
      'tasknumber': data.tasknumber,
      'expendituretype': data.expendituretype,
      'currency': data.currency,
      'poamount': data.poamount,
      'taxrate': data.taxrate,
      'fiscalyear': data.fiscalyear,
      'description': data.description,
      'loa1': data.loa1,
      'operatingunit': data.operatingunit,
    }

    // Initialize polineitems if it's undefined or null
    task.potask.polineitems = task.potask.polineitems || [];

    // update the polineitems array of polineitemIndex index
    task.potask.polineitems[polineitemIndex] = updatedDocument;

    setPaneStatus('pending');

    WriteDocument('tasks', task.taskid, task, true)
      .then(() => {

        reset();
        clearErrors();

        setPaneStatus('onload');
        setEditPOLineItemPaneOpen(false);

      })
      .catch(() => {

        console.log('Error');
        setPaneStatus('error');

      })
  }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

  // Set all the default values on the form
  useEffect(() => {

    if (task === undefined) return;

    const polineitem = task?.potask?.polineitems[polineitemIndex];


    setValue('projectcode', polineitem?.projectcode || '');
    setValue('tasknumber', polineitem?.tasknumber || '');
    setValue('expendituretype', polineitem?.expendituretype || '');
    setValue('currency', polineitem?.currency || '');
    setValue('poamount', polineitem?.poamount || '');
    setValue('taxrate', polineitem?.taxrate || '');
    setValue('fiscalyear', polineitem?.fiscalyear || '');
    setValue('description', polineitem?.description || '');
    setValue('loa1', polineitem?.loa1 || '');
    setValue('operatingunit', polineitem?.operatingunit || '');

    // eslint-disable-next-line
  }, [task, editPOLineItemPaneOpen])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  if (!editPOLineItemPaneOpen) return null;

  // ---------------------------------------------------
  //  Onload
  // ---------------------------------------------------

  if (paneStatus === 'onload') {
    return (
      <div className='Pane-Background'>
        <dialog className='Pane-Container'>

          <form className='flex flex-col gap-3 justify-between' onSubmit={handleSubmit(onSubmit)}>

            {/* ------------------------------- */}
            {/*  PO Line Item Form              */}
            {/* ------------------------------- */}

            <div className='Pane-Form-Container'>

              {/* ===================================== */}
              {/*  Header                               */}
              {/* ===================================== */}

              <h4 className='px-[5%] py-[2.5%] mb-0 text-[22px]'> Edit PO Line Item </h4>
              <hr className='m-0'></hr>

              {/* ===================================== */}
              {/*  Form                                 */}
              {/* ===================================== */}

              <div className='flex flex-col gap-2 px-[5%] py-[2%]'>

                {/* ===================================== */}
                {/*  1. Project Code                      */}
                {/* ===================================== */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'> Project Code <span className='text-[#C4314B]'>*</span> </label>
                  <input
                    className={errors.projectcode ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                    style={{ width: '300px' }}
                    {...register('projectcode', {
                      required: 'This field is required',
                      pattern: {
                        value: /^\d{6}$/,
                        message: 'This field must contain exactly 6 digits',
                      },
                    })}
                    type='text'
                    placeholder={'Project Code'}
                  ></input>

                  {errors.projectcode && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.projectcode?.message}</p>}
                </div>

                {/* ===================================== */}
                {/*  2. Task Number                       */}
                {/* ===================================== */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'>Task Number <span className='text-[#C4314B]'>*</span></label>
                  <input
                    className={errors.tasknumber ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                    style={{ width: '300px' }}
                    type='text'
                    {...register('tasknumber', {
                      required: 'This field is required'
                    })}
                    placeholder={'123456'}
                  ></input>

                  {errors.tasknumber && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.tasknumber?.message}</p>}
                </div>

                {/* ===================================== */}
                {/*  3. Expenditure Type                  */}
                {/* ===================================== */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'>Expenditure Type <span className='text-[#C4314B]'>*</span></label>
                  <input
                    className={errors.expendituretype ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                    style={{ width: '300px' }}
                    type='text'
                    {...register('expendituretype', {
                      required: 'This field is required'
                    })}
                    placeholder={'ICT Costs'}
                  ></input>

                  {errors.expendituretype && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.expendituretype?.message}</p>}
                </div>

                {/* ===================================== */}
                {/*  4. Currency                          */}
                {/* ===================================== */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'>Currency <span className='text-[#C4314B]'>*</span></label>
                  <select
                    className={errors.currency ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                    {...register('currency', {
                      required: 'This field is required'
                    })}
                    style={{ width: '300px' }}
                  >
                    <option hidden value=''>-</option>
                    <option value='AUD'> AUD </option>
                    <option value='SGD'> SGD </option>
                    <option value='GBP'> GBP </option>
                    <option value='USD'> USD </option>
                  </select>

                  {errors.currency && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.currency?.message}</p>}
                </div>

                {/* ===================================== */}
                {/*  5. PO Amount (exTax)                 */}
                {/* ===================================== */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'>PO Amount (exTax)  <span className='text-[#C4314B]'>*</span></label>
                  <input
                    className={errors.poamount ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                    style={{ width: '300px' }}
                    type='number'
                    {...register('poamount', {
                      required: 'This field is required',
                      pattern: {
                        value: /^\d+$/,
                        message: 'This field must contain only numbers',
                      },
                    })}
                    placeholder={'100,000'}
                  ></input>

                  {errors.poamount && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.poamount?.message}</p>}
                </div>

                {/* ===================================== */}
                {/*  6.Tax rate (%)                       */}
                {/* ===================================== */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'>Tax rate (%)<span className='text-[#C4314B]'>*</span></label>
                  <input
                    className={errors.taxrate ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                    style={{ width: '300px' }}
                    type='number'
                    {...register('taxrate', {
                      required: 'This field is required',
                      pattern: {
                        value: /^\d+$/,
                        message: 'This field must contain only numbers',
                      },
                      min: {
                        value: 0,
                        message: 'Value must be at least 0',
                      },
                      max: {
                        value: 100,
                        message: 'Value must not exceed 100',
                      },
                    })}
                    placeholder={'10%'}
                  ></input>

                  {errors.taxrate && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.taxrate?.message}</p>}
                </div>

                {/* ===================================== */}
                {/*  7.Fisacl Year                        */}
                {/* ===================================== */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'>Fisacl Year<span className='text-[#C4314B]'>*</span></label>
                  <select
                    className={errors.fiscalyear ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                    {...register('fiscalyear', {
                      required: 'This field is required'
                    })}
                    style={{ width: '300px' }}
                  >
                    <option hidden value=''>-</option>
                    <option value='FY24'> FY24 </option>
                    <option value='FY25'> FY25 </option>
                    <option value='FY26'> FY26 </option>
                    <option value='FY27'> FY27 </option>
                    <option value='FY28'> FY28 </option>
                  </select>

                  {errors.fiscalyear && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.fiscalyear?.message}</p>}
                </div>

                {/* ===================================== */}
                {/*  8. Description                       */}
                {/* ===================================== */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'>Description</label>
                  <textarea
                    className='Input-Field-TextArea'
                    style={{ height: '110px' }}
                    type='text'
                    placeholder='Add a description...'
                    {...register('description')}
                  ></textarea>
                </div>

                {/* ===================================== */}
                {/*  9. LoA 1                             */}
                {/* ===================================== */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'> LoA 1 </label>
                  <input
                    className='Input-Field-Text'
                    style={{ width: '300px' }}
                    type='text'
                    {...register('loa1')}
                  ></input>
                </div>

                {/* ===================================== */}
                {/*  10. Operating Unit                   */}
                {/* ===================================== */}

                <div className='FormComponent-Row mb-[2%]'>
                  <label className='font-medium'> Operating Unit </label>
                  <input
                    className='Input-Field-Text'
                    style={{ width: '300px' }}
                    type='text'
                    {...register('operatingunit')}
                  ></input>
                </div>

              </div>
            </div>

            {/* ------------------------------- */}
            {/*  Submit Buttons                 */}
            {/* ------------------------------- */}

            <div className='flex flex-row gap-2 justify-self-end'>
              <button
                className='Primary-Button'
                type='submit'
                value='submit'
                disabled={errors.projectcode || errors.tasknumber || errors.expendituretype || errors.currency || errors.poamount || errors.taxrate || errors.fiscalyear}
              >
                Save
              </button>
              <button className='Secondary-Button' onClick={() => {

                reset();
                clearErrors();

                setEditPOLineItemPaneOpen(false)
              }}>
                Cancel
              </button>
            </div>
          </form>
        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Pending
  // ---------------------------------------------------

  else if (paneStatus === 'pending') {
    return (
      <div className='Pane-Background'>
        <dialog className='Pane-Container flex justify-center items-center text-center'>
          <img alt='loading-circle-icon' src={Loading}></img>
        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Error
  // ---------------------------------------------------

  else if (paneStatus === 'error') {
    return (
      <div className='Pane-Background'>

        {/* Pane Container */}
        <dialog className='Pane-Container'>
          <div className='flex flex-col gap-2 w-full justify-center items-center'>

            <img className='my-2 w-[100px]' src={Error} alt='Error'></img>
            <h4 className='mb-0'>Oops! Something went wrong.</h4>
            <p className='text-center leading-[1.7]'>
              An error occurred while we processed your request.
            </p>
            <button
              className='Primary-Button'
              onClick={() => {

                reset();
                clearErrors();

                setPaneStatus('onload');
                setEditPOLineItemPaneOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </dialog>
      </div>

    )
  }

  //------------------------------------------------------
}
