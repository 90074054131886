// Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from './FirebaseConfig';
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore"; 

/**
 * Generic way to add or remove items from an array in a Firestore document
 *
 * @param {string} collectionid
 * @param {string} documentid
 * @param {fieldpath} fieldpath - path to the field ```users``` or ```fieldPath(['users', 'value'])```
 * @param {array} values - new value or value to remove
 * @param {string} action - add or remove
 *
 * ```
 * const collectionid = 'users';
 * const documentid = 'mark.bennett@lendlease.com';
 * const fieldpath = 'projects';
 * const value = 'New Project 1';
 * const action = 'add';
 *
 * updateDocumentArray(collectionid, documentid, fieldpath, value, action).then(() => {
 *
 *   console.log('Updated projects array');
 *
 * });
 *
 * ```
 *
 * Documentation
 * - https://firebase.google.com/docs/firestore/manage-data/add-data#update_elements_in_an_array
 * - https://github.com/firebase/snippets-node/blob/e29c2c3ced6c1a3cb14ad5ab7588dac578c06453/firestore/main/index.js#L238-L256
 */


export default async function AddRemoveDocumentArray(collectionid, documentid, fieldpath, value, action){

  //------------------------------------------------------
  //  Update one document with an object 
  //------------------------------------------------------

    //Update existing document
    try {

        if (action !== 'add' && action !== 'remove') throw new Error(`Invalid 'action' provided, 'AddRemoveDocumentArray' only supports 'add' or 'remove'!`);

        //Firestore Database reference
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);

        // Document Reference
        const docRef = doc(db, collectionid, documentid);

        // Define Array of Promises
        const docPromises = [];

        // Add value to array
        if (action === 'add') {

            docPromises.push(

                updateDoc(docRef, {
                    [fieldpath] : arrayUnion(value),
                }),
    
            );

        }

        // Remove value from array
        if (action === 'remove') {
        
            docPromises.push(

                updateDoc(docRef, {
                    [fieldpath] : arrayRemove(value),
                }),
    
            );

        }

        //Write to document
        return Promise.all(docPromises)

    } catch (error) {
  
        throw new Error(`Function AddRemoveDocumentArray failed to complete, error ${error}`);

    }

  //------------------------------------------------------
}