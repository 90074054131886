/**
 * Generates a unique 6 digit ID based on the user's email address and current date timestamp (used for IDs that are visible to the user)
 * 
 * @param {timestamp} date
 * 
 * ```
 * <td>
 *   {GenerateUniqueId(prefix, emailaddress)}
 * </td>
 * 
 * ```
 */

export default function GenerateUniqueId(prefix, emailaddress){

    try {

        const now = new Date();
        const milliseconds = now.getMilliseconds().toString();

        const encoder = new TextEncoder();
        const data = encoder.encode(emailaddress);

        const emailPrefix = Array.from(data).reduce((sum, byte) => sum + byte, 0);
          
        return `${prefix}-${emailPrefix}${milliseconds}`

    } catch (error) {
        
        return
        
    }
     
}

//--------------------------------------------------------