//================================================================
//  Component: Delete Contract Modal
//================================================================

//  Purpose: This modal will allow users to delete an existing contract

//  Properties:
//    - task = {An object, contains the commercial task details}
//    - deleteContractPane = {useState, used to store the current contract}
//    - setDeleteContractPane = {useState, used to toggle the visibility of the delete contract modal}

//  Example:
//    <DeleteContractModal
//      task={task}
//      deleteContractPane={deleteContractPane}
//      setDeleteContractPane={setDeleteContractPane}
//    ></DeleteContractModal> 

//================================================================

//Libraries
import React, { useState, useContext } from 'react';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components

//Functions
import WriteDocument from '../../../../../Library/WriteDocument';
import DeleteFile from '../../../../../Library/DeleteFile';

//Images
import Error from '../../../../../Components/Images/Icon_ErrorFatal_Red.svg';
import Warning from '../../../../../Components/Images/Icon_Warning_Red.svg';

export default function DeleteContractModal({
  task,
  deleteContractPane, 
  setDeleteContractPane
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to set modal status > 'onload', 'error'
  const [modalStatus, setModalStatus] = useState('onload');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Used to delete the existing contract
  function deleteContract() {

    const allPromises = [];
          
    //------------------------------------------------------
    //  Update Document in Firestore
    //------------------------------------------------------

    const updatedContracts = task?.signedcontracts.filter(contract => contract.contractid !== deleteContractPane?.contractid);
    
    const updatedTask = {
      'lastmodifieddate': new Date(),
      'lastmodifiedby': {
        'email': getUser?.emailaddress,
        'givenname': getUser?.givenname,
        'surname': getUser?.surname
    },
      'signedcontracts': updatedContracts
    }

    allPromises.push(
      WriteDocument('tasks', task?.taskid, updatedTask, true)
    );
    
    //------------------------------------------------------
    //  Delete File in Storage
    //------------------------------------------------------

    deleteContractPane?.documents?.forEach(doc => {
      
      allPromises.push(
        DeleteFile(`/tasks/${task?.taskid}/${doc?.fileId}`)
      );

    });

    //------------------------------------------------------
    //  Resolve Promises
    //------------------------------------------------------

    return Promise.all(allPromises)
    .then(() =>{

      // Close Modal
      setDeleteContractPane(undefined);

    })
    .catch((error) => {

      console.log(error);
      setModalStatus('error');
    
    })

  }
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  if (deleteContractPane === undefined) return null;

  // ---------------------------------------------------
  //  Onload
  // ---------------------------------------------------

  if (modalStatus === 'onload') {
    return (
      <div className='Modal-Background'>
        
        {/* Modal Container */}
        <dialog className='Warning-Modal-Container'>

              {/* Header */}
              <div className='flex flex-row gap-3 items-center'>
                  <img src={Warning} alt='Warning'></img>
                  <h4>You are about to delete this contract</h4>
              </div>

              {/* Body */}
              <p className='max-w-[450px] mt-2 mb-3'> 
                  Are you sure you want to delete this contract? This change cannot be undone.
              </p>

              {/* Buttons */}
              <div className='flex flex-row gap-2'>
                  <button className='Warning-Button' onClick={() => deleteContract()}>Delete</button>
                  <button 
                      className='Secondary-Button' 
                      style={{color: '#424242', border: '1px solid #424242'}} 
                      onClick={() => setDeleteContractPane(undefined)}
                  >
                      Cancel
                  </button>
              </div>

        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Error
  // ---------------------------------------------------
  
  else if (modalStatus === 'error') {
    return (
      <div className='Modal-Background'>
        
        {/* Modal Container */}
        <dialog className='Warning-Modal-Container justify-center items-center'>
  
          <img className='my-1 w-[50px]' src={Error} alt='Error'></img> 

          <h4 className='mb-0'>Oops! Something went wrong.</h4>

          <p className='text-center leading-[1.7]'>
            An error occurred while we processed your request.
          </p>

          <button 
              className='Warning-Button' 
              onClick={() => {
                  setModalStatus('onload');
                  setDeleteContractPane(undefined);
              }}
          >
              Close
          </button>

        </dialog>

      </div>
    )
  }
    
  //------------------------------------------------------
}