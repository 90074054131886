//================================================================
//  Component: View Contract Pane
//================================================================

//  Purpose: This pane will allow users to view an individual contract

//  Properties:
//    - viewContractPane = {useState, used to store the current contract and determine if the pane is visible}
//    - setViewContractPane = {useState, used to toggle the visibility of the view contract pane}

//  Example:
//    <ViewContractPane
//      viewContractPane={viewContractPane}
//      setViewContractPane={setViewContractPane}
//    ></ViewContractPane>    

//================================================================


//Libraries
import React from 'react';

//Contexts

//Components

//Functions

//Images
import File from '../../../../../Components/Images/Icon_File_Black.svg';

export default function ViewContractPane({
  viewContractPane, 
  setViewContractPane
}) {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    if (viewContractPane === undefined) return null;

    // ---------------------------------------------------
    //  Pane Visible
    // ---------------------------------------------------

    else {
      return (
        <div className='Pane-Background'>
          <dialog className='Pane-Container'>

            <div className='flex flex-col gap-3'>

              {/* ------------------------------- */}
              {/*  Contract                       */}
              {/* ------------------------------- */}
              
              <div className='Pane-Form-Container overflow-x-hidden'>
      
                {/* ===================================== */}
                {/*  Header                               */}
                {/* ===================================== */}
                
                <h4 className='px-[5%] py-[2.5%] mb-0 text-[22px]'> Contract | <b className='font-medium uppercase '>{viewContractPane?.contractid}</b> </h4>
                <hr className='m-0'></hr>

                {/* ===================================== */}
                {/*  Summary                              */}
                {/* ===================================== */}

                <div className='flex flex-col gap-2 px-[5%] py-[2%]'>

                  {/* ------------------------------- */}
                  {/*  Signees                        */}
                  {/* ------------------------------- */}

                  <div className='FormComponent-Row'>
                    <label className='font-medium'> Signees </label>
  
                    {
                      viewContractPane?.signees.map((signee, index) => (
                        <div key={index} className='w-full flex flex-row justify-between border border-[#D8D8D8] px-3 py-2 rounded-[5px] bg-[#F8F8F8]'>
                          <div className='flex flex-col'>
                            <p className='font-medium m-0'>{signee?.signeeemail}</p>
                            <p className='text-[13px] m-0 text-slate-600'>{signee?.signingparty}</p>
                            <p className='mb-0 text-[13px]'><i>Date signed: {signee?.signaturedate.split('-')[2]}/{signee?.signaturedate.split('-')[1]}/{signee?.signaturedate.split('-')[0]}</i></p>
                          </div>
                        </div>
                      ))
                    }

                  </div>

                  {/* ------------------------------- */}
                  {/*  Created By                     */}
                  {/* ------------------------------- */}

                  <div className='FormComponent-Row'>
                    <label className='font-medium'> Created By </label>
                    <input
                        className='Input-Field-Text'
                        type='text'
                        value={viewContractPane?.createdby?.email}
                        disabled
                    ></input>
                  </div>

                  {/* ------------------------------- */}
                  {/*  Short Description              */}
                  {/* ------------------------------- */}

                  <div className='FormComponent-Row'>
                    <label className='font-medium'> Short Description </label>
                    <textarea
                        className='Input-Field-TextArea overflow-y-auto'
                        style={{height: '200px'}}
                        type='text'
                        value={viewContractPane?.shortdescription?.length === 0 ? '-' : viewContractPane?.shortdescription}
                        disabled
                    ></textarea>
                  </div>

                  {/* ------------------------------- */}
                  {/*  Document                       */}
                  {/* ------------------------------- */}

                  <div className='FormComponent-Row mb-[2%]'>
                    <label className='font-medium mb-1'> Attached Document(s) </label>
                    {
                      viewContractPane?.documents.map((file, index) => (
                        <div key={index} className='flex flex-row gap-2 items-center rounded-md border border-[#D8D8D8] p-2'>
                          <img src={File} alt='file'></img>
                          <a href={file?.fileUrl} target='_blank' rel='noopener noreferrer'> {file?.fileName} </a>
                        </div>
                      ))
                    }
                  </div>

                </div>

              </div>


              {/* ------------------------------- */}
              {/*  Buttons                        */}
              {/* ------------------------------- */}
              
              <div>
                <button className='Primary-Button' onClick={() => { setViewContractPane(undefined) }}> 
                  Close 
                </button>
              </div>

            </div>
                            
          </dialog>
        </div>
      )
    }

  //------------------------------------------------------
}
