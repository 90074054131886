//================================================================
//  Page: Requests
//================================================================

//  Purpose: This page shows all requests at each stage

//  Example:
//    <Requests></Requests>    

//================================================================


// Libraries
import React, { useReducer } from 'react';

// Contexts

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';

// Tabs
import Triage from './Tabs/Triage';
import ReviewAndNegotiate from './Tabs/ReviewAndNegotiate';
import Execute from './Tabs/Execute';
import Completed from './Tabs/Completed';
import Disengagement from './Tabs/Disengagement';
import All from './Tabs/All';

// Functions

// Images

// CSS
import './Requests.css';


export default function Requests() {
  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store the total requests per stage/view
    const [requests, setRequests] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'triageRequests': [],
        'triageRequestsFiltered': [],
        'reviewAndNegotiateRequests': [],
        'reviewAndNegotiateRequestsFiltered': [],
        'executeRequests': [],
        'executeRequestsFiltered': [],
        'disengagementRequests': [],
        'disengagementRequestsFiltered': [],
      }
    );

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='Page-Header-Container'>
            <p className='font-medium text-[20px] mb-0'> Requests </p>
          </div>
        }
        body={
          <div>

            <TabView
              defaultView={1}

              //------------------------------------------------------
              //  Triage
              //------------------------------------------------------

              oneTitle={
                <div className='flex flex-row gap-2'>
                  Triage
                  <div className='flex items-center justify-center h-[22px] w-[22px] rounded-full bg-[#006866] text-white text-[12px] font-bold' hidden={requests?.triageRequests?.length === 0}>
                    {requests?.triageRequests?.length}
                  </div>
                </div>
              }
              oneRoute = {'/requests?view=triage'}
              oneContent={
                <Triage
                  requests={requests}
                  setRequests={setRequests}
                ></Triage>
              }

              //------------------------------------------------------
              // Review and Negotiate
              //------------------------------------------------------

              twoTitle={
                <div className='flex flex-row gap-2'>
                  Review and Negotiate
                  <div className='flex items-center justify-center h-[22px] w-[22px] rounded-full bg-[#006866] text-white text-[12px] font-bold' hidden={requests?.reviewAndNegotiateRequests?.length === 0}>
                    {requests?.reviewAndNegotiateRequests?.length}
                  </div>
                </div>
              }
              twoRoute = {'/requests?view=reviewandnegotiate'}
              twoContent={
                <ReviewAndNegotiate
                  requests={requests}
                  setRequests={setRequests}
                ></ReviewAndNegotiate>
              }

              //------------------------------------------------------
              // Execute
              //------------------------------------------------------

              threeTitle={
                <div className='flex flex-row gap-2'>
                  Execute
                  <div className='flex items-center justify-center h-[22px] w-[22px] rounded-full bg-[#006866] text-white text-[12px] font-bold' hidden={requests?.executeRequests?.length === 0}>
                    {requests?.executeRequests?.length}
                  </div>
                </div>
              }
              threeRoute = {'/requests?view=execute'}
              threeContent={
                <Execute
                  requests={requests}
                  setRequests={setRequests}
                ></Execute>
              }

              //------------------------------------------------------
              // Completed
              //------------------------------------------------------

              fourTitle={'Completed'}
              fourRoute = {'/requests?view=completed'}
              fourContent={
                <Completed></Completed>
              }

              //------------------------------------------------------
              // Disengagement
              //------------------------------------------------------

              fiveTitle={
                <div className='flex flex-row gap-2'>
                  Disengagement
                  <div className='flex items-center justify-center h-[22px] w-[22px] rounded-full bg-[#006866] text-white text-[12px] font-bold' hidden={requests?.disengagementRequests?.length === 0}>
                    {requests?.disengagementRequests?.length}
                  </div>
                </div>
              }
              fiveRoute = {'/requests?view=disengagement'}
              fiveContent={
                <Disengagement
                  requests={requests}
                  setRequests={setRequests}
                ></Disengagement>
              }

              //------------------------------------------------------
              // All
              //------------------------------------------------------

              sixTitle={'All'}
              sixRoute = {'/requests?view=all'}
              sixContent={
                <All></All>
              }

            ></TabView>    

          </div>
        }
      ></PageComponent> 
    )
}
