//================================================================
//  Component: Add Contract Pane
//================================================================

//  Purpose: This pane will allow users to add a new contract to the commercial task

//  Properties:
//    - addContractPaneOpen = {useState, used to determine if the pane is visible and contains the current task}
//    - setAddContractPaneOpen = {useState, used to toggle the visibility of the add contract pane}

//  Example:
//    <AddContractPane
//      addContractPaneOpen={addContractPaneOpen}
//      setAddContractPaneOpen={setAddContractPaneOpen}
//    ></AddContractPane>    

//================================================================


//Libraries
import React, { useContext, useState, useReducer } from 'react';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import UserSearch from '../../../../../Components/UserSearch/UserSearch';

//Functions
import GenerateUniqueId from '../../../../../Library/GenerateUniqueId';
import UploadFile from '../../../../../Library/UploadFile';
import WriteDocument from '../../../../../Library/WriteDocument';

//Images
import ClearDisabled from '../../../../../Components/Images/Icon_ClearDisabled_Grey.svg';
import Clear from '../../../../../Components/Images/Icon_Clear_Red.svg';
import Error from '../../../../../Components/Images/Icon_ErrorFatal_Red.svg';
import Loading from '../../../../../Components/Images/Image_Loading_Ripple.svg';
import FileIcon from '../../../../../Components/Images/Icon_File_Black.svg';

export default function AddContractPane({
  addContractPaneOpen, 
  setAddContractPaneOpen
}) {

  //------------------------------------------------------
  //  useContext
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to set pane status > 'onload', 'error'
    const [paneStatus, setPaneStatus] = useState('onload');

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store the form inputs
    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'newSigningParty': '',
        'newSigneeEmail': '',
        'newUserInput': '',
        'newUserGivenName': '',
        'newUserSurname': '',
        'newSignatureDate': '',
        'signeeErrorMessage': '',
        'signees': [],
        'shortdescription': '',
        'documents': [],
        'documentsErrorMessage': ''
      }
    );


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Adds a signee to 'signees'
    function addSignee() { 

      // Check for duplicates
      if (formData.signees?.filter((object) => object.signeeemail === formData.newSigneeEmail)?.length > 0) {

        return setFormData({
          'signeeErrorMessage': 'This signee already exists.'
        });

      }

      // Update Form Data
      setFormData({
        'newSigningParty': '',
        'newSigneeEmail': '',
        'newUserInput': '',
        'newUserGivenName': '',
        'newUserSurname': '',
        'newSignatureDate': '',
        'signeeErrorMessage': '',
        'signees': [
          ...formData.signees, 
          {
            'signingparty': formData.newSigningParty,
            'signeeemail': formData.newSigneeEmail.length > 0 ? formData.newSigneeEmail : formData.newUserInput,
            'signaturedate': formData.newSignatureDate
          }
        ]
      });

    };

    // Submit Handler
    function handleSubmit() {

      // ----------------------------------------------
      //  Validation Checks
      // ----------------------------------------------

      // OnSubmit Validation of all required fields
      let preventSubmit = false;

      // Check new signee fields
      if (formData.newSigningParty?.length > 0) {

        if (formData.newSigneeEmail?.length === 0 && formData.newUserInput?.length === 0) {

          preventSubmit = true;
          formData.signeeErrorMessage = 'Please enter an email address.'

        } else if (formData.newSignatureDate?.length === 0) {

          preventSubmit = true;
          formData.signeeErrorMessage = 'Please enter a date.'

        } else {

          formData.signees.push({
            'signingparty': formData.newSigningParty,
            'signeeemail': formData.newSigneeEmail?.length > 0 ? formData?.newSigneeEmail : formData?.newUserInput,
            'signaturedate': formData.newSignatureDate
          });
          formData.signeeErrorMessage = '';

        }

      } else {
        formData.signeeErrorMessage = '';

      }

      // Field Validation | Signees
      if (formData.signees?.length === 0 && (formData.newSigningParty?.length === 0 || formData.newSigneeEmail?.length === 0 || formData.newSignatureDate?.length === 0)) {

        preventSubmit = true;
        formData.signeeErrorMessage = 'This field is required. Please add at least one signee.';

      } else {

        formData.signeeErrorMessage = '';

      }; 

      // Field Validation | Documents
      if (formData.documents?.length === 0) {

        preventSubmit = true;
        formData.documentsErrorMessage = 'This field is required. Please attach at least one document.';

      } else {

        formData.documentsErrorMessage = '';

      }; 

      setFormData(formData);

      // Check if ALL validations have passed
      if (preventSubmit === true) return;

      setPaneStatus('pending');

      // ----------------------------------------------
      //  Upload Contract
      // ----------------------------------------------

      const filePromises = [];

      // Loop through documents > Upload File
      formData.documents.forEach((document) => {

        filePromises.push(
          UploadFile(`tasks/${addContractPaneOpen?.taskid}/${document?.fileId}`, document?.fileObject),
        );

      });

      // Resolve Promises
      Promise.all(filePromises)
      .then((urls) => {

        // Update Document in Firestore
        const updatedDocs = [];

        // Loop through docs > Add URL
        formData?.documents.forEach((document, index) => {

          updatedDocs.push({
            'fileUrl': urls[index],
            'fileId': document?.fileId,
            'fileName': document?.fileName,
            'fileType': document?.fileType
          });

        });

        const contractObject = {
          'contractid': GenerateUniqueId('cn', getUser?.emailaddress),
          'signees': formData?.signees.sort((a, b) => {

            // Convert date strings to Date objects for accurate comparison
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB; // Returns a negative, zero, or positive value
            
          }),
          'shortdescription': formData?.shortdescription,
          'documents': updatedDocs,
          'createdby': {
            'email': getUser?.emailaddress,
            'givenname': getUser?.givenname,
            'surname': getUser?.surname
          }
        };

        // Get Latest Signature Date for Execution Date
        let executiondate = '';
        const signeeSortByDate = formData.signees.sort((a, b) => new Date(b.signaturedate) - new Date(a.signaturedate)); // Sort signees by signature date
        const maxDate = new Date(signeeSortByDate[0]?.signaturedate)

        if (maxDate > (addContractPaneOpen?.agreement?.executiondate === '' ? new Date('1900-01-01') : new Date(addContractPaneOpen?.agreement?.executiondate))) {
          executiondate = signeeSortByDate[0]?.signaturedate
        }

        const updatedTask = {
          'signedcontracts': [...addContractPaneOpen?.signedcontracts, contractObject],
          'lastmodifieddate': new Date(),
          'lastmodifiedby': {
            'email': getUser?.emailaddress,
            'givenname': getUser?.givenname,
            'surname': getUser?.surname
          },
          'agreement': {
            'executiondate': executiondate
          }
        }

        return WriteDocument('tasks', addContractPaneOpen?.taskid, updatedTask, true)
        .then(() => {

          // Reset useState
          setFormData(
            {
              'newSigningParty': '',
              'newSigneeEmail': '',
              'newUserInput': '',
              'newUserGivenName': '',
              'newUserSurname': '',
              'newSignatureDate': '',
              'signeeErrorMessage': '',
              'signees': [],
              'shortdescription': '',
              'documents': [],
              'documentsErrorMessage': '',
            }
          );

          // Close Pane
          setPaneStatus('onload');
          setAddContractPaneOpen(undefined);

        })

      })
      .catch((error) => {
        console.log(error)
        setPaneStatus('error');

      })

    }
    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    if (addContractPaneOpen === undefined) return null;

    // ---------------------------------------------------
    //  Onload
    // ---------------------------------------------------

    if (paneStatus === 'onload') {
      return (
        <div className='Pane-Background'>
          <dialog className='Pane-Container' style={{ width: '70vw' }}>

            <div className='flex flex-col gap-3 justify-between'>

              {/* ------------------------------- */}
              {/*  Contract Form                  */}
              {/* ------------------------------- */}
              
              <div className='Pane-Form-Container' style={{ width: '56vw' }}>
      
                {/* ===================================== */}
                {/*  Header                               */}
                {/* ===================================== */}
                
                <h4 className='px-[5%] py-[2.5%] mb-0 text-[22px]'> Add Contract </h4>
                <hr className='m-0'></hr>

                <label className='px-[5%] pt-[4%] pb-[2%]'>
                  Attach a signed contract to the commercial request.
                </label>

                {/* ===================================== */}
                {/*  Form                                 */}
                {/* ===================================== */}

                <div className='flex flex-col gap-2 px-[5%]'>

                  {/* ------------------------------- */}
                  {/*  Signees List                   */}
                  {/* ------------------------------- */}

                  {
                    formData?.signees.map((signee, index) => (
                      <div className='grid grid-cols-3' key={index}>
                        
                        {/*  Signing Party */}
                        <div className='FormComponent-Row'>
                          <label hidden={index > 0} className='font-medium'> Signing Party * </label>

                          <select className='Input-Field-Select' style={{width: '100%'}} disabled>
                            <option>{signee?.signingparty}</option>
                          </select>
                        </div>

                        {/* Signee Email */}
                        <div className='FormComponent-Row'>
                          <label hidden={index > 0} className='font-medium'> Signee Email * </label>
                          <input
                            className='Input-Field-Text'
                            style={{width: '100%'}}
                            type='email'
                            value={signee?.signeeemail}
                            disabled
                          ></input>
                        </div>

                        {/* Date Signed */}
                        <div className='flex flex-row gap-2 items-end'>

                          <div className='FormComponent-Row'>
                              <label hidden={index > 0} className='font-medium'> Date Signed * </label>
                              <input
                                className='Input-Field-Text'
                                style={{width: '100%'}}
                                type='date'
                                value={signee?.signaturedate}
                                disabled
                              ></input>
                          </div>

                          {/* Clear Button */}
                          <img
                            className='pb-2 cursor-pointer min-w-[30px]'
                            src={Clear} 
                            alt='clear' 
                            onClick={() => {
                              formData.signees?.splice(index, 1);
                              setFormData(formData);
                            }}
                          ></img>

                        </div>
                      </div>
                    ))
                  }

                  {/* ------------------------------- */}
                  {/*  + Add Contract                 */}
                  {/* ------------------------------- */}

                  <div className='grid grid-cols-3 items-center'>
                  
                    {/*  Signing Party */}
                    <div className='FormComponent-Row'>
                      <label hidden={formData.signees.length > 0} className='font-medium'> Signing Party * </label>

                      <select
                        className='Input-Field-Select'
                        style={{width: '100%'}}
                        value={formData?.newSigningParty}
                        onChange={(e) => setFormData({ 'newSigningParty': e.target.value })}
                      >
                        <option hidden value=''>-</option>
                        <option value='Lendlease'>Lendlease</option>
                        <option value='Other Party'>Other Party</option>
                      </select>

                    </div>

                    {/* Signee Email */}
                    <div className='FormComponent-Row'>
                      <label hidden={formData.signees.length > 0} className='font-medium'> Signee Email * </label>
                      {

                        formData.newSigningParty === 'Lendlease' ?

                        // User Search
                        <UserSearch
                          selectedUser={formData}
                          setSelectedUser={setFormData}
                        ></UserSearch>
                        :

                        // Email Input Field
                        <input
                          className={formData.signeeErrorMessage === 'Please enter an email address.' ? 'Input-Field-Text-Error' : 'Input-Field-Text'}
                          style={{ width: '100%' }}
                          type='email'
                          placeholder='Enter signee email'
                          value={formData?.newSigneeEmail}
                          onChange={(e) => setFormData({ 'newSigneeEmail': e.target.value })}
                        ></input>

                      }
                    </div>

                    {/* Date Signed */}
                    <div className='flex flex-row gap-2 items-end'>

                      <div className='FormComponent-Row'>
                        <label hidden={formData.signees?.length > 0} className='font-medium'> Date Signed * </label>
                        <input
                          className={formData.signeeErrorMessage === 'Please enter a date.' ? 'Input-Field-Text-Error' : 'Input-Field-Text'}
                          style={{ width: '100%' }}
                          type='date'
                          value={formData?.newSignatureDate}
                          onChange={(e) => setFormData({ 'newSignatureDate': e.target.value })}
                        ></input>
                      </div>

                      {/* Clear Button */}
                      <div className='pb-2 cursor-pointer'>
                        {
                          formData?.newSigningParty?.length > 0 || formData?.newSignatureDate === '' || formData.newSigneeEmail?.length > 0 || formData.newUserInput?.length > 0 ?
                          <img className='cursor-pointer min-w-[30px]' src={Clear} alt='clear' onClick={() => {
                            setFormData({
                              'newSigningParty': '',
                              'newSigneeEmail': '',
                              'newSignatureDate': '',
                              'newUserInput': '',
                              'newUserGivenName': '',
                              'newUserSurname': '',
                              'signeeErrorMessage': '',
                            });
                          }}></img>
                          :
                          <img src={ClearDisabled} alt='clear'></img>
                        }
                      </div>

                    </div>

                  </div>

                  {/* Error Message */}
                  <label className='font-medium text-[#DE0000] ml-[5px]' hidden={formData?.signeeErrorMessage.length === 0}>{formData?.signeeErrorMessage}</label>

                  {/* Add Contract Button */}
                  <button 
                    className='Secondary-Button w-fit' 
                    style={{ marginLeft: '5px'}}
                    onClick={() => addSignee()}
                    disabled={formData.newSigningParty?.length === 0 || (formData.newSigneeEmail?.length === 0 && formData.newUserInput?.length === 0) || formData.newSignatureDate?.length === 0}
                  >
                    <strong>+</strong> Add
                  </button>

                  {/* ------------------------------- */}
                  {/*  Short Description              */}
                  {/* ------------------------------- */}

                  <div className='FormComponent-Row'>
                    <label className='font-medium'> Short Description </label>
                    <textarea
                        className='Input-Field-TextArea'
                        style={{height: '200px'}}
                        type='text'
                        placeholder='Enter description'
                        onChange={(e) => setFormData({ 'shortdescription': e.target.value })}
                        value={formData?.shortdescription}
                    ></textarea>
                  </div>

                  {/* ------------------------------- */}
                  {/*  Document(s)                    */}
                  {/* ------------------------------- */}

                  <div className='FormComponent-Row mb-[4%]'>
                    <label className='font-medium mb-1'> Attach Document(s) * </label>
                    
                    {/* File Upload */}
                    <div className='Attach-File-Onload-Container'>
                        
                      <input
                          className={formData?.documentsErrorMessage?.length > 0 ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                          style={{padding: '0px'}}
                          type='file'
                          accept='.pdf,.doc,.docx'
                          onChange={(evt) => {
  
                            const fileObject = evt.target.files[0];
                                
                            // Validate File Types
                            const allowedFileTypes = ['pdf', 'doc', 'docx'];

                            let fileType;
                            fileType = fileObject?.name.split('.');
                            fileType = fileType[fileType?.length - 1].toLowerCase();

                            if (allowedFileTypes.includes(fileType) === false) {
                                return setFormData({ 'documentsErrorMessage': `Please upload a file in the following format(s): ${allowedFileTypes.join(', ')}.` });
                    
                            }

                            // Update Form Data
                            const fileId = `${Date.now().toString()}${Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)}.${fileType}`;

                            setFormData({
                              'documents': [
                                  ...formData.documents,
                                  {
                                    'fileUrl': '',
                                    'fileId': fileId,
                                    'fileName': fileObject.name,
                                    'fileObject': fileObject,
                                    'fileType': fileType
                                  }
                              ],
                              'documentsErrorMessage': ''
                            });
                            
                            // Clear File Input
                            evt.target.value = '';
  
                          }}
                      ></input>
    
                    </div>

                    <label className='flex flex-row gap-[5px] text-[13px] text-slate-500 mt-1' hidden={formData?.documentsErrorMessage?.length > 0}>
                      Supported File Types: PDF, DOC, DOCX
                    </label>

                    {/* Error Message */}
                    <label className='font-medium text-[#DE0000] ml-[5px]' hidden={formData?.documentsErrorMessage.length === 0}>{formData?.documentsErrorMessage}</label>

                    {/* Uploads Table */}
                    {
                      formData?.documents.length > 0 &&
                      <div className='w-full flex flex-col rounded-md border border-[#D8D8D8] mt-1'>
                        {
                          formData?.documents.map((doc, index) => (
                            <div key={index} className='flex flex-row justify-between items-center border-b border-b-[#D8D8D8] last:border-0 px-3 py-1'>
        
                              {/* File Name */}
                              <div className='flex flex-row gap-2 items-center'>
                                <img src={FileIcon} alt='file'></img>
                                <p className='mb-0'>{doc?.fileName}</p>
                              </div>
                              
                              {/* Delete Button */}
                              <div className='Cancel-Icon min-w-[30px] mt-[5px] mb-0 p-0' onClick={() => setFormData({ 'documents': formData.documents.filter(item => item!== doc) })}></div>

                            </div>
                          ))
                        }           
                      </div>
                    }

                  </div>

                </div>

              </div>

              {/* ------------------------------- */}
              {/*  Submit Buttons                 */}
              {/* ------------------------------- */}
              
              <div className='flex flex-row gap-2 justify-self-end'>
                <button 
                  className='Primary-Button' 
                  onClick={() => handleSubmit()}
                  disabled={formData?.documents.length === 0 || (formData.signees?.length === 0 && (formData?.newSigningParty.length === 0 || formData.newSignatureDate.length === 0 || (formData.newSigneeEmail.length === 0 && formData.newUserInput?.length === 0)))}
                > 
                  Submit 
                </button>
                <button className='Secondary-Button' onClick={() => {
                  setFormData({
                    'newSigningParty': '',
                    'newSigneeEmail': '',
                    'newUserInput': '',
                    'newUserGivenName': '',
                    'newUserSurname': '',
                    'newSignatureDate': '',
                    'signeeErrorMessage': '',
                    'signees': [],
                    'shortdescription': '',
                    'documents': [],
                    'documentsErrorMessage': '',
                  });
                  setAddContractPaneOpen(undefined)
                }}> Cancel </button>
              </div>

            </div>
                            
          </dialog>
        </div>
      )
    }

    // ---------------------------------------------------
    //  Pending
    // ---------------------------------------------------

    else if (paneStatus === 'pending') {
      return (
        <div className='Pane-Background'>
          <dialog className='Pane-Container flex justify-center items-center text-center'>
            <img alt='loading-circle-icon' src={Loading}></img>
          </dialog>
        </div>
      )

    }

    // ---------------------------------------------------
    //  Error
    // ---------------------------------------------------

    else if (paneStatus === 'error') {
      return (
        <div className='Pane-Background'>
          
          {/* Pane Container */}
          <dialog className='Pane-Container'>
            <div className='flex flex-col gap-2 w-full justify-center items-center'>

              <img className='my-2 w-[100px]' src={Error} alt='Error'></img> 
              <h4 className='mb-0'>Oops! Something went wrong.</h4>
              <p className='text-center leading-[1.7]'>
                An error occurred while we processed your request.
              </p>
              <button 
                className='Primary-Button' 
                onClick={() => {
                  setFormData({
                    'newSigningParty': '',
                    'newSigneeEmail': '',
                    'newUserInput': '',
                    'newUserGivenName': '',
                    'newUserSurname': '',
                    'newSignatureDate': '',
                    'signeeErrorMessage': '',
                    'signees': [],
                    'shortdescription': '',
                    'documents': [],
                    'documentsErrorMessage': ''
                  })
                  setPaneStatus('onload');
                  setAddContractPaneOpen(undefined);
                }}
              >
                Close
              </button>
            
            </div>
          </dialog>
        </div>

      )
    }

  //------------------------------------------------------
}
