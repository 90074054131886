//================================================================
//  Component: History Timeline
//================================================================

//  Purpose: This is the standard for creating a component

//  Documentation:
//  - https://cruip.com/3-examples-of-brilliant-vertical-timelines-with-tailwind-css/#example-1
//  - https://github.com/cruip/cruip-tutorials/blob/main/vertical-timelines/index.html

//  Properties:
//    - item = {An object, contains the  }

//  Example:
//    <HistoryTimeline
//      item={item}
//    ></HistoryTimeline>    

//================================================================


//Libraries
import React from 'react';

//Contexts

//Components

//Functions
import ConvertDate from '../../Library/ConvertDate';

//Images

//CSS

export default function HistoryTimeline({
  item
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------



  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='relative pl-32 py-2 group'>

        {/* <!-- Vertical line (::before) ~ Date ~ Title ~ Circle marker (::after) --> */}
        <div className='flex flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-0 before:h-full before:px-px before:bg-slate-300 before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-0 after:w-2 after:h-2 after:bg-[#00A7A4] after:border-4 after:box-content after:border-slate-50 after:rounded-full after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5'>
            
            {/* Initials */}
            <time className='absolute left-8 inline-flex items-center justify-center'>
            <label className='w-[45px] h-[45px] text-center uppercase bg-[#8ECDCA] p-[7px] rounded-full text-lg text-[#141C25] font-medium'>
                {item?.actionedby?.givenname[0]}{item?.actionedby?.surname[0]}
            </label>
            </time>

            {/* Date & Message */}
            <div className='flex flex-col'>
            <label className='font-light'>{ConvertDate(item?.activitydate)}</label>

            <div className='font-medium text-[#00A7A4]'>
              <b className='font-medium text-black capitalize'>{item?.actionedby?.givenname} {item?.actionedby?.surname}</b> {item?.action}
            </div>
            
            </div>

        </div>

    </div>
  )
}
